<template>
    <div class="topNav">
        <nav class="top">
            <!--<a href="/orders" title="Orders" class="commerce"><img src="~@/assets/images/basco-circle.svg" width="30" /></a>--><router-link class="commerce" to="/orders" style="color: #FFFFFF;"><img src="~@/assets/images/basco-circle.svg" width="30" /></router-link> <h5>Commerce</h5>
        </nav>
    </div>
</template>

<script>
// @ is an alias to /src
export default {}
</script>
