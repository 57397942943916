<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-12">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-12">
                <div class="pageCard">
                  <div class="row">
                    <div class="col-sm-4">
                      <v-select v-model="order.company_id" :options="companies" :reduce="companies => companies.id" label="name" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4"></div>
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-4">
                            <label>Issue Date</label>
                          </div>
                          <div class="col-sm-8">
                            <date-picker
                              v-model="order.issued_at"
                              format="YYYY-MM-DD"
                              type="date"
                              valueType="format"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <label>Delivery Date</label>
                          </div>
                          <div class="col-sm-8">
                            <date-picker
                              v-model="order.due_at"
                              format="YYYY-MM-DD"
                              type="date"
                              valueType="format"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <label>Payment Date</label>
                          </div>
                          <div class="col-sm-8">
                            <date-picker
                              v-model="order.payment_due_at"
                              format="YYYY-MM-DD"
                              type="date"
                              valueType="format"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <label>Reference</label>
                          </div>
                          <div class="col-sm-8">
                            <input type="text" v-model="order.reference" />
                          </div>
                        </div>
                      </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-sm-4">
                          <label>Currency</label>
                        </div>
                        <div class="col-sm-8">
                          <div class="commerceSelect">
                            <select v-model="order.currency">
                              <option value="GBP">British Pound (GBP)</option>
                              <option value="EUR">Euro (EUR)</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <table class="orderItem">
                        <thead>
                          <tr>
                            <th>Item Name</th>
                            <th style="width: 9%;">Quantity</th>
                            <th style="width: 16%;">Cost per unit</th>
                            <th style="width: 80px;">VAT</th>
                            <th style="width: 12%;">Total</th>
                            <th style="width: 30px;"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="purchaseitem, index in purchaseitems" :key="purchaseitem.id">
                            <td><VariantSelector v-model="purchaseitem.variant_id" @input="updateVariant(index, purchaseitem.variant_id)" /></td>
                            <td><input type="text" v-model="purchaseitem.quantity" @input="updateQuantity(index)" /></td>
                            <td>
                              <div class="inputTypeForUnitLeftOrder">
                                <input type="text" class="text" v-model="purchaseitem.price" />
                                <div class="inputUnitLeftOrder" v-if="order.currency === 'GBP'">&pound;</div>
                                <div class="inputUnitLeftOrder" v-if="order.currency === 'EUR'">&euro;</div>
                              </div>
                            </td>
                            <td>
                              <div class="inputTypeForUnitOrder">
                                <input type="text" class="text" v-model="purchaseitem.vat" />
                                <div class="inputUnitOrder">%</div>
                              </div>
                            </td>
                            <td>{{ purchaseitem.total_cost }}</td>
                            <td><div align="center"><img src="~@/assets/images/icon/remove.svg" width="10" @click.prevent="deleteItem(index)" /></div></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="8">
                              <div class="addNewItemLink" @click.prevent="addNewItem()">
                                <font-awesome-icon icon="plus" /> Add another item
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <textarea rows="6" v-model="order.notes" placeholder="Order notes for the supplier"></textarea>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <div class="row">
                        <div class="col-sm-6">
                          Total Units
                        </div>
                        <div class="col-sm-6">
                          0
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          Total
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'GBP'">
                          &pound;{{ order.total }}
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'EUR'">
                          &euro;{{ order.total }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div align="right">
                        <input type="submit" value="Create Purchase Order" @click.prevent="createPurchaseOrder()">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import axios from 'axios'

import VariantSelector from '@/components/form/VariantSelector.vue'

export default {
  components: { DatePicker, VariantSelector },
  data () {
    return {
      companies: [],
      order: [],
      purchaseitems: [],
      variantresponse: []
    }
  },
  created () {
    axios
      .get('/companies/companies_list/?type=supplier')
      .then(response => {
        this.companies = response.data
      })
  },
  methods: {
    addNewItem () {
      this.purchaseitems.push({})
    },
    deleteItem (id) {
      this.$delete(this.purchaseitems, id)
    },
    updateVariant (index, variant) {
      axios
        .get(`/variants/${variant}`)
        .then(response => {
          this.variantresponse = response.data
          this.purchaseitems[index].price = this.variantresponse.buy_eur
          this.purchaseitems[index].vat = this.variantresponse.taxrate
        })
        .catch(err => console.log(err))
    },
    updateQuantity (index) {
      var unitPrice = this.purchaseitems[index].price
      var totalPrice = (unitPrice * this.purchaseitems[index].quantity)
      this.purchaseitems[index].total_cost = totalPrice.toFixed(2)
    },
    createPurchaseOrder () {
      const newpurchaseorder = {
        purchaseitems: this.purchaseitems,
        company_id: this.order.company_id,
        status: this.order.status,
        currency: this.order.currency,
        po_number: this.order.po_number,
        issued_at: this.order.issued_at,
        due_at: this.order.due_at,
        payment_due_at: this.order.payment_due_at,
        reference: this.order.reference,
        notes: this.order.notes
      }
      axios
        .post('/purchaseorders', newpurchaseorder)
        .then(response => {
          this.$router.push({ name: 'PurchaseOrders' })
        })
        .catch(err => console.log(err))
    }
  }
}
</script>
