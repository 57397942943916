var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid",staticStyle:{"padding-left":"0px","padding-right":"0px"}},[_vm._m(0),_c('div',{staticClass:"pageContainer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"pageSubContainer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-10"},[_vm._m(1),_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.saveDocuments.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-sm-8"},[_c('input',{attrs:{"type":"file"},on:{"change":_vm.onBrochureChange}})])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-sm-8"},[_c('input',{attrs:{"type":"file"},on:{"change":_vm.onPriceListChange}})])]),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-sm-8"},[_c('input',{attrs:{"type":"file"},on:{"change":_vm.onAllergensChange}})])]),_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"col-sm-1"})])])]),_c('div',{staticClass:"col-sm-3"})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"titleRow"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('h4',[_vm._v("Wholesale")])]),_c('div',{staticClass:"col-sm-5"}),_c('div',{staticClass:"col-sm-2"}),_c('div',{staticClass:"col-sm-1"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('h2',[_vm._v("Documents")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-4"},[_c('label',[_vm._v("Brochure")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-4"},[_c('label',[_vm._v("Pricelist")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-4"},[_c('label',[_vm._v("Allergens")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",staticStyle:{"margin-top":"30px"}},[_c('div',{staticClass:"col-sm-12"},[_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"}),_c('div',{staticClass:"col-sm-8"},[_c('button',{attrs:{"type":"submit","name":"submit"}},[_vm._v("Save Documents")])])])
}]

export { render, staticRenderFns }