<template>
  <div class="app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px; padding-right: 0px;">
                <left-nav></left-nav>
                 <div id="appContainer">
                   <header class="titleRow">
                      <div class="row">
                      <div class="col-sm-6">
                          <h4>Welcome to Commerce, {{ user.name }}</h4>
                      </div>
                      <div class="col-sm-6">
                          <div align="right">ICON</div>
                      </div>
                      </div>
                    </header>
                    <div class="row">
                      <div class="col-sm-12">
                        {{ info }}
                      </div>
                    </div>
                 </div>
             </div>
        </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import LeftNav from '@/components/nav/Dashboard.vue'

// import axios from 'axios'

export default {
  data () {
    return {
      user: {
        name: localStorage.getItem('name')
      },
    }
  },
  // name: 'Home',
  components: {
    LeftNav
  }
  /*
  data () {
    return {
      info: null
    }
  },
  mounted () {
    axios
      .get('https://api.coindesk.com/v1/bpi/currentprice.json')
      .then(response => (this.info = response))
  } */
}
</script>
