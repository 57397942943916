<template>
<div>
  <header class="titleRow">
    <div class="row">
      <div class="col-sm-4">
          <h4>Orders</h4>
      </div>
      <div class="col-sm-5">
        <input type="text" v-model="keywordSearch" v-on:keyup.enter="searchOrders" placeholder="Search by name, company or reference ..." />
      </div>
      <div class="col-sm-2">
        <div align="right">
          <router-link :to="{name: 'OrdersNew'}" class="btnGreenHeader" style="margin-top: -3px;">Create New</router-link>
        </div>
      </div>
      <div class="col-sm-1">
          
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <ul class="filterNav filterNavBottom">
          <router-link :to="{name: 'Orders'}"><li>All</li></router-link>
          <router-link :to="{name: 'Orders', query:{type: 'active'}}"><li>Active</li></router-link>
          <router-link :to="{name: 'Orders', query:{type: 'payment'}}"><li>Require payment</li></router-link>
          <router-link :to="{name: 'Orders', query:{type: 'sending'}}"><li>Require sending</li></router-link>
          <router-link :to="{name: 'Orders', query:{type: 'retail'}}"><li>Retail</li></router-link>
          <router-link :to="{name: 'Orders', query:{type: 'wholesale'}}"><li>Wholesale</li></router-link>
        </ul>
      </div>
    </div>
  </header>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="normalCol">Order No</div>
          <div class="normalCol">Name</div>
          <div class="normalCol">Status</div>
          <div class="smallCol">Picked</div>
          <div class="smallCol">Sent</div>
          <div class="smallCol">Paid</div>
          <div class="normalCol">Total</div>
          <div class="normalCol">Created Date</div>
          <div class="normalCol">Ship By Date</div>
        </div>
      </div>
    </div>
    <div class="row" v-for="order in orders" :key="order.id">
      <router-link class="resultLink" :to="{name: 'OrdersView', params: { id: order.id }}">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol">
            <div align="center">
              <input type="checkbox" name="checkbox" />
            </div>
          </div>
          <div class="normalCol">{{ order.order_number }}</div>
          <div class="normalCol">{{ order.name }}</div>
          <div class="normalCol"><span class="itemStatus" :class="order.status">{{ order.status }}</span></div>
          <div class="smallCol"><span class="emptyPoint" :class="order.picked_status"></span></div>
          <div class="smallCol"><span class="emptyPoint" :class="order.fulfillment_status"></span></div>
          <div class="smallCol"><span class="emptyPoint" :class="order.payment_status"></span></div>
          <div class="normalCol">&pound;{{ order.order_total.toFixed(2) }}</div>
          <div class="normalCol">{{ order.issued_at | formatDate }}</div>
          <div class="normalCol">{{ order.ship_at | formatDate }}</div>
        </div>
      </div>
      </router-link>
    </div>
  </div>
  <div class="paginationSpacer"></div>
  <div class="paginationContainer">
    <div class="row">
      <div class="col-sm-12">
        <div align="right">
          Showing page {{ pagination.current_page }} of {{ pagination.last_page }} <a class="btnPaginate" @click.prevent="getOrders(pagination.prev_page_url)">Previous</a> <a class="btnPaginate" @click.prevent="getOrders(pagination.next_page_url)">Next</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      orders: {},
      pagination: {},
      keywordSearch: []
    }
  },
  created () {
    this.getOrders(),
    this.$watch(
      () => this.$route.query,
      (toParams, previousParams) => {
        this.getOrders (this.page)
      }
    )
  },
  methods: {
    getOrders (page) {
      if (typeof page === 'undefined') {
        page = '/orders?page=1'
      }
      var pageFilter = this.$route.query.type
      var pageGet = page + '&type=' + pageFilter
      axios
        .get(pageGet)
        .then((res) => {
          this.orders = res.data.data
          this.pagination = res.data
        })
    },
    searchOrders () {
      const keywords = { keywords: this.keywordSearch }
      axios
        .post('/orders/search', keywords)
        .then(response => {
          this.orders = response.data.data
          this.pagination = response.data
        })
    }
  }
}
</script>
