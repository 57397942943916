<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="normalCol">Adjustment No</div>
          <div class="normalCol">Status</div>
          <div class="normalCol">Reason</div>
          <div class="normalCol">Quantity</div>
          <div class="normalCol">Value</div>
          <div class="normalCol">Updated</div>
        </div>
      </div>
    </div>
    <div class="row" v-for="stockadjustment in stockadjustments" :key="stockadjustment.id">
      <router-link :to="{name: 'StockAdjustmentsView', params: { id: stockadjustment.id }}">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol">
            <div align="center">
              <input type="checkbox" name="checkbox" />
            </div>
          </div>
          <div class="normalCol">{{ stockadjustment.adjustment_number }}</div>
          <div class="normalCol"><span class="itemStatus" :class="stockadjustment.status">{{ stockadjustment.status }}</span></div>
          <div class="normalCol">{{ stockadjustment.reason }}</div>
          <div class="normalCol">{{ stockadjustment.quantity }}</div>
          <div class="normalCol">&pound;{{ stockadjustment.total_value }}</div>
          <div class="normalCol">{{ stockadjustment.updated_at | formatDate }}</div>
        </div>
      </div>
      </router-link>
    </div>
  </div>
  <div class="paginationContainer">
    <div class="row">
      <div class="col-sm-12">
        <div align="right">
          Showing page {{ pagination.current_page }} of {{ pagination.last_page }} <a class="btnPaginate">Previous</a> <a class="btnPaginate">Next</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      stockadjustments: {},
      pagination: {}
    }
  },
  created () {
    this.getStockAdjustments()
  },
  methods: {
    getStockAdjustments (page) {
      if (typeof page === 'undefined') {
        page = '/stockadjustments?page=1'
      }
      axios
        .get(page)
        .then((res) => {
          this.stockadjustments = res.data.data
          this.pagination = res.data
        })
    }
  }
}
</script>
