<template>
  <div>
    <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
      <header class="titleRow">
        <div class="row">
          <div class="col-sm-4">
              <h4>Home Sliders</h4>
          </div>
          <div class="col-sm-5">
            
          </div>
          <div class="col-sm-2">
            <div align="right">
              <router-link to="/wholesale/homesliders/new" class="btnGreenHeader" style="margin-top: -3px;">Create New</router-link>
            </div>
          </div>
          <div class="col-sm-1">
              
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <ul class="filterNav filterNavBottom">
              <li class="active">All</li>
            </ul>
          </div>
        </div>
      </header>
      <div class="row">
        <div class="col-sm-12">
          <div id="resultsHeader">
            <div class="checkboxCol"></div>
            <div class="quadCol">Title</div>
            <div class="normalCol">Status</div>
            <div class="normalCol">Last Updated</div>
          </div>
        </div>
      </div>
      <div class="row" v-for="slider in sliders" :key="slider.id">
        <router-link class="resultLink" :to="{name: 'WholesaleHomeSlidersView', params: { id: slider.id }}">
        <div class="col-sm-12">
          <div id="resultsBody">
            <div class="checkboxCol">
              <div align="center">
                <input type="checkbox" name="checkbox" />
              </div>
            </div>
            <div class="quadCol">{{ slider.title }}</div>
            <div class="normalCol">{{ slider.status }}</div>
            <div class="normalCol">{{ slider.updated_at | formatDate }}</div>
          </div>
        </div>
        </router-link>
      </div>
    </div>
    <div class="paginationSpacer"></div>
    <div class="paginationContainer">
      <div class="row">
        <div class="col-sm-12">
          <div align="right">
            <!--Showing page {{ pagination.current_page }} of {{ pagination.last_page }} <a class="btnPaginate" @click.prevent="getProducts(pagination.prev_page_url)">Previous</a> <a class="btnPaginate" @click.prevent="getProducts(pagination.next_page_url)">Next</a>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  
  import axios from 'axios'
  
  export default {
    data () {
      return {
        keywordSearch: null,
        sliders: [],
        pagination: {}
      }
    },
    created () {
      this.getSliders()
    },
    methods: {
      getSliders () {
        axios
          .get('/wholesaleplatform/wholesalesliders')
          .then((res) => {
            this.sliders = res.data
            //console.log(res)
          })
      }
    }
  }
  </script>
  