<template>
  <div class="app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px; padding-right: 0px;">
                <left-nav></left-nav>
                 <div id="appContainer" class="bgPage">
                   <header class="titleRow">
                    <div class="row">
                      <div class="col-sm-11">
                          <h4><router-link :to="{name: 'Orders'}">Orders</router-link> / <router-link :to="{name: 'OrdersView', params: { id: $route.params.id }}">{{ order.order_number }}</router-link></h4>
                      </div>
                      <div class="col-sm-1">
                          <!--<div align="right">ICON</div>-->
                      </div>
                    </div>
                  </header>
                  <app-content></app-content>
                 </div>
             </div>
        </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import LeftNav from '@/components/nav/Orders.vue'
import AppContent from '@/views/orders/OrdersEdit.vue'

import axios from 'axios'

export default {
  // name: 'Home',
  components: {
    LeftNav,
    AppContent
  },
  data () {
    return {
      order: {}
    }
  },
  created () {
    axios
      .get(`/orders/${this.$route.params.id}`)
      .then((res) => {
        this.order = res.data.order
      })
  }
}
</script>
