<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Company Details</h2>
                  </div>
                </div>
                <form @submit.prevent="editCompany">
                <div class="row">
                  <div class="col-sm-4">
                    <label>Company Name</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="companies.name" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Company Type</label>
                  </div>
                  <div class="col-sm-8">
                    <div class="commerceSelect">
                      <select v-model="companies.company_type">
                        <option value="business">Business Customer</option>
                        <option value="consumer">Consumer</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Company Code</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="companies.company_code" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Tax Number</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="companies.tax_number" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>EORI Number</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="companies.eori_number" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Company Phone Number</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="companies.phone" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Company Fax Number</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="companies.fax" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Company Website</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="companies.website" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Company Email Address</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="companies.email" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Description</label>
                  </div>
                  <div class="col-sm-8">
                    <textarea v-model="companies.description" rows="6"></textarea>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Tags</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="companies.tags" />
                  </div>
                </div>
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Defaults</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Default Price List</label>
                  </div>
                  <div class="col-sm-8">
                    <div class="commerceSelect">
                      <select v-model="companies.default_price_list_id">
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Default Discount</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="companies.default_discount_rate" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Default Payment Term</label>
                  </div>
                  <div class="col-sm-8">
                    <div class="commerceSelect">
                      <select v-model="companies.default_payment_term">
                        <option value="Pre-payment">Pre-payment</option>
                        <option value="7 days">7 days</option>
                        <option value="28 days">28 days</option>
                        <option value="45 days">45 days</option>
                        <option value="60 days">60 days</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <button type="submit" name="submit">Save Customer</button>
                  </div>
                </div>
                </form>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      companies: {}
    }
  },
  created () {
    axios
      .get(`/companies/${this.$route.params.id}`)
      .then((res) => {
        this.companies = res.data
      })
  },
  methods: {
    editCompany () {
      axios
        .put(`/companies/${this.$route.params.id}`, this.companies)
        .then(response => (
          this.$toast.success("Customer saved", {}),
          this.$router.push({ name: 'CustomersEdit', params: { id: this.$route.params.id } })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }
  }
}
</script>
