<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <header class="titleRow">
      <div class="row">
        <div class="col-sm-4">
            <h4>Wholesale</h4>
        </div>
        <div class="col-sm-5">
          
        </div>
        <div class="col-sm-2">
        
        </div>
        <div class="col-sm-1">
            
        </div>
      </div>
    </header>
  </div>
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      keywordSearch: null,
      companies: [],
      pagination: []
    }
  },
  created () {
  },
  /* watch: {
    keywordSearch (after, before) {
      this.searchCompanies()
    }
  }, */
  methods: {
  }
}
</script>
