import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/index'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 4000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTable, faClipboardList, faUserFriends, faFileAlt, faDolly, faChartBar, faCogs, faPlus, faAngleDoubleRight, faRightFromBracket, faWineBottle, faBarsProgress, faCashRegister, faImage, faTags, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import moment from 'moment'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import VueSelectImage from 'vue-select-image'
require('vue-select-image/dist/vue-select-image.css')
Vue.use(VueSelectImage)

import axios from 'axios'
Vue.prototype.$http = axios

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_COMMERCE_API

const token = localStorage.getItem('token');

axios.defaults.headers.common = {
  'Authorization': `Bearer ${token}`,
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

require('@/assets/css/app.css?v=1.1')

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
})


Vue.config.productionTip = false

Vue.use(Toast)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)


library.add(faTable, faClipboardList, faUserFriends, faFileAlt, faDolly, faChartBar, faCogs, faPlus, faAngleDoubleRight, faRightFromBracket, faWineBottle, faBarsProgress, faCashRegister, faImage, faTags, faRotateRight)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('v-select', vSelect)

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
