<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="normalCol">Name</div>
          <div class="normalCol">Phone</div>
          <div class="normalCol">Mobile</div>
          <div class="doubleCol">Email</div>
          <div class="normalCol">Title</div>
          <div class="normalCol">Department</div>
        </div>
      </div>
    </div>
    <div class="row" v-for="contact in contacts" :key="contact.id">
      <router-link class="resultLink" :to="{name: 'CustomersContactEdit', params: { id: $route.params.id, cid: contact.id }}">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol">
            <div align="center">
              <input type="checkbox" name="checkbox" />
            </div>
          </div>
          <div class="normalCol">{{ contact.first_name }} {{ contact.last_name }}</div>
          <div class="normalCol">{{ contact.phone_number }}</div>
          <div class="normalCol">{{ contact.mobile }}</div>
          <div class="doubleCol">{{ contact.email }}</div>
          <div class="normalCol">{{ contact.job_title }}</div>
          <div class="normalCol">{{ contact.department }}</div>
        </div>
      </div>
      </router-link>
    </div>
    <div class="row" id="resultsFooterLink">
      <div class="col-sm-12">
        <div align="center">
          <router-link :to="{name: 'CustomersContactNew', params: { id: $route.params.id }}"><font-awesome-icon icon="plus" /> Add New Contact</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      contacts: []
    }
  },
  created () {
    axios
      .get(`/companies/${this.$route.params.id}/contacts`)
      .then(response => {
        this.contacts = response.data
      })
  },
  methods: {
  }
}
</script>
