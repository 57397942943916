<template>
  <div class="app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px; padding-right: 0px;">
                <left-nav></left-nav>
                 <div id="appContainer">
                   <header class="titleRow">
                    <div class="row">
                      <div class="col-sm-4">
                          <h4>Customers / <router-link :to="{name: 'CustomersEdit', params: { id: $route.params.id }}">{{ company.name }}</router-link></h4>
                      </div>
                      <div class="col-sm-3">
                      </div>
                      <div class="col-sm-4">
                        <div align="right">
                          <router-link :to="{name: 'CustomersEditCompany', params: { id: $route.params.id }}" class="btnGreenLineHeader" style="margin-top: -3px;">Edit Customer</router-link>
                        </div>
                      </div>
                      <div class="col-sm-1">
                          <div align="right">ICON</div>
                      </div>
                    </div>
                  </header>
                  <app-content></app-content>
                  <addresses></addresses>
                 </div>
             </div>
        </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import LeftNav from '@/components/nav/Customers.vue'
import AppContent from '@/views/customers/Overview.vue'
import Addresses from '@/views/customers/AddressList.vue'

import axios from 'axios'

export default {
  // name: 'Home',
  components: {
    LeftNav,
    AppContent,
    Addresses
  },
  data () {
    return {
      company: {}
    }
  },
  created () {
    axios
      .get(`/companies/${this.$route.params.id}`)
      .then((res) => {
        this.company = res.data.data
      })
  }
}
</script>
