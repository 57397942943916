<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-12">
                <div class="pageCard">
                  <div class="row">
                    <div class="col-sm-4">
                     <h4 class="reference">{{ order.order_number }}</h4>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <form @submit.prevent="updateOrder">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="itemsGroup">
                          <div class="itemsList">
                            <dt class="item">Contact</dt>
                            <dd class="item">
                              <div class="commerceSelect">
                                <select v-model="order.contact_id">
                                  <option v-for="contact in contacts" :key="contact.id" :value="contact.id" :selected="contact.id === order.contact_id">{{ contact.first_name }} {{ contact.last_name }}</option>
                                </select>
                              </div>
                            </dd>
                          </div>
                          <div class="itemsList">
                            <dt class="item">Billing</dt>
                            <dd class="item">
                              <div class="commerceSelect">
                                <select v-model="order.billing_address_id">
                                  <option v-for="address in addresses" :key="address.id" :value="address.id" :selected="address.id === order.billing_address_id">({{ address.label }}) {{ address.address1 }}, {{ address.address2 }}, {{ address.city }}, {{ address.county }}, {{ address.postcode }}</option>
                                </select>
                              </div>
                            </dd>
                          </div>
                          <div class="itemsList">
                            <dt class="item">Shipping</dt>
                            <dd class="item">
                              <div class="commerceSelect">
                                <select v-model="order.shipping_address_id">
                                  <option v-for="address in addresses" :key="address.id" :value="address.id" :selected="address.id === order.shipping_address_id">({{ address.label }}) {{ address.address1 }}, {{ address.address2 }}, {{ address.city }}, {{ address.county }}, {{ address.postcode }}</option>
                                </select>
                              </div>
                            </dd>
                          </div>
                        </div>
                      </div>
                        <div class="col-sm-4">
        <!--<v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="100%"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormatted"
              label="Date"
              hint="MM/DD/YYYY format"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              @blur="date = parseDate(dateFormatted)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>-->
                          <div class="itemsGroup">
                            <div class="itemsList">
                              <dt class="item">Issue Date</dt>
                              <dd class="item">
                                <date-picker
                                  v-model="order.issued_at"
                                  format="YYYY-MM-DD"
                                  type="date"
                                  valueType="format"
                                ></date-picker>
                              </dd>
                            </div>
                            <div class="itemsList">
                              <dt class="item">Delivery Date</dt>
                              <dd class="item">
                                <date-picker
                                  v-model="order.ship_at"
                                  format="YYYY-MM-DD"
                                  type="date"
                                  valueType="format"
                                ></date-picker>
                              </dd>
                            </div>
                            <div class="itemsList">
                              <dt class="item">Reference</dt>
                              <dd class="item"><input type="text" class="text" v-model="order.customer_reference" /></dd>
                            </div>
                          </div>
                        </div>
                      <div class="col-sm-4">
                        <div class="itemsGroup">
                          <div class="itemsList">
                            <dt class="item">Currency</dt>
                            <dd class="item">{{ order.currency }}</dd>
                          </div>
                          <div class="itemsList">
                            <dt class="item">Delivery</dt>
                            <dd class="item">
                              <div class="commerceSelect">
                                <select v-model="order.delivery_method">
                                  <option value="Courier">Courier</option>
                                  <option value="Van">Van</option>
                                  <option value="Pallet">Pallet</option>
                                  <option value="Collection">Collection</option>
                                </select>
                              </div>
                            </dd>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <hr />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <table class="orderItem">
                          <thead>
                            <tr>
                              <th>Item Name</th>
                              <th style="width: 9%;">Quantity</th>
                              <th style="width: 9%;">Available</th>
                              <th style="width: 16%;">Price (GBP)</th>
                              <th style="width: 80px;">Discount</th>
                              <th style="width: 80px;">VAT</th>
                              <th style="width: 12%;">Total (GBP)</th>
                              <th style="width: 30px;"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="orderitem in orderitems" :key="orderitem.id" style="vertical-align: top;">
                              <td>
                                <span :id="'product-' + orderitem.id">
                                {{ orderitem.name }}<br />
                                </span>
                                <span style="font-size:10px;"><font-awesome-icon icon="plus" /> Note</span>
                                <input type="text" class="orderProductInput" v-model="orderitem.label" v-on:input="updateOrderItem(orderitem.id, orderitem.quantity, orderitem.unit_price, orderitem.discount, orderitem.taxrate, orderitem.label)" />
                                <b-tooltip :target="'product-' + orderitem.id" placement="top">
                                  <table width="480">
                                    <tr>
                                      <td width="40%" style="vertical-align: top;">
                                        <table width="100%">
                                          <tr>
                                            <td style="text-align: left; color: #c2bfb3;" width="70%">Stock on hand: </td>
                                            <td style="text-align: left;">{{ orderitem.stock_on_hand }}</td>
                                          </tr>
                                          <tr>
                                            <td style="text-align: left; color: #c2bfb3;">Allocated Qty: </td>
                                            <td style="text-align: left;">{{ orderitem.allocated }}</td>
                                          </tr>
                                          <tr>
                                            <td style="text-align: left; color: #c2bfb3;">Available Qty: </td>
                                            <td style="text-align: left;">{{ orderitem.available }}</td>
                                          </tr>
                                          <tr>
                                            <td style="text-align: left; color: #c2bfb3;">On Purchase: </td>
                                            <td style="text-align: left;">{{ orderitem.on_purchase }}</td>
                                          </tr>

                                        </table>
                                      </td>
                                      <td width="60%" style="text-align: left; color: #c2bfb3; line-height: 1.2; vertical-align: top;">
                                        {{ orderitem.notes }}
                                      </td>
                                    </tr>
                                  </table>
                                </b-tooltip>
                              </td>
                              <td><input type="text" v-model="orderitem.quantity" v-on:input="updateOrderItem(orderitem.id, orderitem.quantity, orderitem.unit_price, orderitem.discount, orderitem.taxrate, orderitem.label)" /></td>
                              <td></td>
                              <td>
                                <div class="inputTypeForUnitLeftOrder">
                                  <!--<input type="text" class="text" v-model="orderitem.unit_price" v-on:input="updateOrderItem(orderitem.id, orderitem.quantity, orderitem.unit_price, orderitem.discount, orderitem.taxrate, orderitem.label)" />
                                  <div class="inputUnitLeftOrder">&pound;</div>-->
                                  &pound;{{ orderitem.unit_price.toFixed(2) }}
                                </div>
                              </td>
                              <td>
                                <div class="inputTypeForUnitOrder">
                                  <input type="text" class="text" v-model="orderitem.discount" v-on:input="updateOrderItem(orderitem.id, orderitem.quantity, orderitem.unit_price, orderitem.discount, orderitem.taxrate, orderitem.label)" />
                                  <div class="inputUnitOrder">%</div>
                                </div>
                              </td>
                              <td>
                                {{ orderitem.taxrate }}%
                              </td>
                              <td>&pound;{{ orderitem.total_price.toFixed(2) }}</td>
                              <td><div align="center"><img src="~@/assets/images/icon/remove.svg" width="10" v-on:click="deleteOrderItem(orderitem.id)" /></div></td>
                            </tr>
                            
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colspan="1">
                                <!--
                                <div class="addNewItemLink" @click.prevent="addNewItem()">
                                  <font-awesome-icon icon="plus" /> Add another item
                                </div>
                                -->
                                <div class="" style="margin-top: 10px;"><font-awesome-icon icon="plus" /> Add another item<br />
                                 <input type="text" class="orderProductInput" v-model="productInput" @focus="onFocus" @blur="onBlur" />
                                  <div class="inputResults" v-show="isVisible">
                                    <ul>
                                      <li v-for="product in products" :key="product.id" @click.prevent="addOrderItemProduct(product.id, order.id)">
                                        <div class="row">
                                          <div class="col-sm-1">
                                            <img :src="product.image" width="100%" />
                                          </div>
                                          <div class="col-sm-7">
                                            {{product.name}}<br /><span>{{product.sku}}</span>
                                            <br />
                                            <span style="font-size: 9px; line-height: 11px;">{{product.notes}}</span>
                                          </div>
                                          <div class="col-sm-4">
                                            Available: {{product.available}}<br />
                                            <span>Allocated: {{product.allocated}}<br />
                                            Stock on Hand: {{product.stock_on_hand}}<br />
                                            On Purchase: {{product.on_purchase}}<br /></span>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <textarea v-model="order.notes" rows="5" placeholder="Order notes"></textarea>
                      </div>
                      <div class="col-sm-1"></div>
                      <div class="col-sm-5">
                        <!--<div class="row">
                          <div class="col-sm-6">
                            Total Units
                          </div>
                          <div class="col-sm-6">
                            0
                          </div>
                        </div>-->
                        <div class="row">
                          <div class="col-sm-6">
                            Sub-Total
                          </div>
                          <div class="col-sm-6">
                            &pound;{{ order.order_subtotal.toFixed(2) }}
                          </div>
                        </div>
                        <!--<div class="row">
                          <div class="col-sm-6">
                            Delivery
                          </div>
                          <div class="col-sm-6">
                            &pound;{{ order.order_delivery }}
                          </div>
                        </div>-->
                        <div class="row">
                          <div class="col-sm-6">
                            VAT
                          </div>
                          <div class="col-sm-6">
                            &pound;{{ order.order_vat.toFixed(2) }}
                          </div>
                        </div>
                        <div class="row" style="margin-top:10px;">
                          <div class="col-sm-6">
                            <strong>Total</strong>
                          </div>
                          <div class="col-sm-6">
                            <strong>&pound;{{ order.order_total.toFixed(2) }}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <hr />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-1">
                        <!--<label style="text-align: left;">Tags</label>-->
                      </div>
                      <div class="col-sm-3">
                        <!--<v-combobox v-model="order.tags"
                          clearable
                          multiple
                          small-chips
                          outlined
                        ></v-combobox>
                        -->
                      </div>
                      <div class="col-sm-8">
                        <div align="right">
                          <input type="submit" value="Save Order">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-12">
                <!--
                <div class="pageCard greenTop">
                  <div class="row">
                    <div class="col-sm-12">
                      <h6>Order</h6>
                    </div>
                  </div>
                  <div class="row" v-if="order.status === 'draft'">
                    <div class="col-sm-12">
                      <a class="btnOrderAction" @click.prevent="finaliseOrder()">Process <font-awesome-icon icon="angle-double-right" /></a>
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-sm-6">
                      Status:
                    </div>
                    <div class="col-sm-6">
                      {{ order.status }}
                    </div>
                  </div>
                </div>
              -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import axios from 'axios'

export default {
  components: { DatePicker },
  data () {
    return {
      order: [],
      orderitems: [],
      contacts: [],
      addresses: [],
      variants: [],
      productInput: null,
      isVisible: false,
      products: [],
      isLoading: true,
      history: [],
      activeKey: false
    }
  },
  watch: {
    productInput (after, before) {
      if (after === null || before === null) {
        this.DoNothing()
      } else {
        this.searchProduct()
      }
    }
  },
  created () {
    axios
      .get(`/orders/${this.$route.params.id}`)
      .then((res) => {
        this.order = res.data.order
        axios
          .get(`/companies/${this.order.company_id}/contacts`)
          .then(response => {
            this.contacts = response.data
          })
        axios
          .get(`/companies/${this.order.company_id}/addresses`)
          .then(response => {
            this.addresses = response.data
          })
        axios
          .get(`/orders/${this.$route.params.id}/orderitems`)
          .then((res) => {
            this.orderitems = res.data
          })
      })
    
    /*axios
      .get('/variants/list')
      .then(response => {
        this.variants = response.data
      })*/
  },
  methods: {
    onFocus: function () {
      this.isVisible = true
      this.searchProduct()
    },
    onBlur: function () {
      //this.isVisible = false
    },
    searchProduct () {
      const keywords = { keywords: this.productInput }
      this.isVisible = true
      axios
        .post('/products/search', keywords)
        .then(response => {
          this.products = response.data.data
        })
    },

    noteActive(i) {
        return this.activeKey === i;
    },

    toggleActive(i) {
        this.activeKey = this.noteActive(i) ? null : i;
    },

    updateOrderItem (id, quantity, unitPrice, discount, vat, label) {
      const orderitemsReq = {
        id: id,
        quantity: quantity,
        discount: discount,
        unit_price: unitPrice,
        vat: vat,
        label: label
      }
      axios
        .put(`/orders/${this.$route.params.id}/orderitems/edit`, orderitemsReq)
        .then(response => (
          this.refreshOrder()
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    updateOrderItemProduct (id, orderitemid) {
      const updateproductitem = {
        variant_id: id,
        orderitem_id: orderitemid,
        company_id: this.order.company_id,
        order_id: this.$route.params.id
      }
      axios
        .put(`/orders/${this.$route.params.id}/orderitems/editvariant`, updateproductitem)
        .then(response => {
          this.refreshOrder()
          this.isVisible = false
        })
    },
    addOrderItemProduct (id) {
      const addproductitem = {
        variant_id: id,
        company_id: this.order.company_id,
        order_id: this.$route.params.id
      }
      axios
        .post(`/orders/${this.$route.params.id}/orderitems/add`, addproductitem)
        .then(response => {
          this.refreshOrder()
          this.isVisible = false
        })
    },
    deleteOrderItem (id) {
      const orderitemsReq = {
        id: id
      }
      axios
        .put(`/orders/${this.$route.params.id}/orderitems/delete`, orderitemsReq)
        .then(response => (
          this.refreshOrder()
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    refreshOrder () {
      axios
        .get(`/orders/${this.$route.params.id}`)
        .then((res) => {
          this.order = res.data.order
        })
      axios
        .get(`/orders/${this.$route.params.id}/orderitems`)
        .then((res) => {
          this.orderitems = res.data
        })
    },
    updateOrder () {
      axios
        .put(`/orders/${this.$route.params.id}`, this.order)
        .then(response => (
          this.$toast.success("Order saved", {}),
          this.$router.push({ name: 'OrdersView', params: { id: this.$route.params.id } })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    addNewItem () {
      this.orderitems.push({})
    }
  }
}
</script>
