<template>
    <div id="leftNavSecond">
        <nav><h5>{{ $route.meta.navName }}</h5></nav>
        <nav class="secondNav">
        <ul>
            <router-link to="/purchase-orders"><li>Purchase Orders</li></router-link>
            <router-link to="/stock-adjustments"><li>Stock Adjustments</li></router-link>
            <router-link to="/stocktakes"><li>Stocktakes</li></router-link>
            <router-link to="/batch"><li>Batch</li></router-link>
        </ul>
        </nav>
    </div>
</template>

<script>
export default {}
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
/*
export default {
  name: 'Home',
  components: {
    HelloWorld
  }
} */
</script>
