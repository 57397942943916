<template>
  <div class="app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px; padding-right: 0px;">
                <left-nav></left-nav>
                 <div id="appContainer">
                   <header class="titleRow">
                    <div class="row">
                      <div class="col-sm-4">
                          <h4>Stocktakes</h4>
                      </div>
                      <div class="col-sm-5">
                        <input type="text" name="stocktakeSearch" id="stocktakeSearch" placeholder="Search by reference ..." />
                      </div>
                      <div class="col-sm-2">
                        <div align="right">
                          <router-link to="/stocktakes/new" class="btnGreenHeader" style="margin-top: -3px;">Create New</router-link>
                        </div>
                      </div>
                      <div class="col-sm-1">
                          
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <ul class="filterNav filterNavBottom">
                          <li class="active">All</li>
                          <li>Active</li>
                        </ul>
                      </div>
                    </div>
                  </header>
                  <app-content></app-content>
                 </div>
             </div>
        </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import LeftNav from '@/components/nav/StockControl.vue'
import AppContent from '@/views/stock-takes/Stocktakes.vue'

export default {
  // name: 'Home',
  components: {
    LeftNav,
    AppContent
  }
}
</script>
