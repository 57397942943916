<template>
  <div class="app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px; padding-right: 0px;">
                <left-nav></left-nav>
                 <div id="appContainer">
                   <header class="titleRow">
                    <div class="row">
                      <div class="col-sm-4">
                          <h4><router-link :to="{name: 'StockTakeView', params: { id: category.stocktake_id }}">Stocktake</router-link> / {{ category.name }}</h4>
                      </div>
                      <div class="col-sm-5">
                        
                      </div>
                      <div class="col-sm-2">
                        <div align="right">
                          <a class="btnGreenHeader" @click="completeStockTakeCategory()" style="margin-top: -3px;">Complete</a>
                        </div>
                      </div>
                      <div class="col-sm-1">
                          
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <ul class="filterNav filterNavBottom">
                          <li class="active">All</li>
                        </ul>
                      </div>
                    </div>
                  </header>
                  <app-content></app-content>
                 </div>
             </div>
        </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import LeftNav from '@/components/nav/Warehouse.vue'
import AppContent from '@/views/warehouse/stocktake/Category.vue'

import axios from 'axios'

export default {
  // name: 'Home',
  components: {
    LeftNav,
    AppContent
  },
  data () {
    return {
      category: [],
    }
  },
  created () {
    axios
      .get(`/stocktakes/categories/${this.$route.params.id}/summary`)
      .then(response => {
        this.category = response.data
      })
  },
  methods: {
    completeStockTakeCategory() {
      const postcategory = {
        status: 'Completed',
        items: ''
      }
      axios
        .put(`/stocktakes/categories/${this.$route.params.id}`, postcategory)
        .then(response => (
          this.$router.push({ name: 'StockTakeView', params: { id: this.category.stocktake_id } }),
          this.$toast.success("Category Completed", {})
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
  }
}
</script>

