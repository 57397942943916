<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="normalCol">Order#</div>
          <div class="normalCol">Status</div>
          <div class="smallCol">Paid</div>
          <div class="smallCol">Sent</div>
          <div class="normalCol">Value</div>
          <div class="normalCol">Created</div>
          <div class="normalCol">Last Updated</div>
        </div>
      </div>
    </div>
    <div class="row" v-for="order in orders" :key="order.id">
      <router-link class="resultLink" :to="{name: 'OrdersView', params: { id: order.id }}">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol"></div>
          <div class="normalCol">{{ order.order_number }}</div>
          <div class="normalCol"><span class="itemStatus" :class="order.status">{{ order.status }}</span></div>
          <div class="smallCol"><span class="emptyPoint"></span></div>
          <div class="smallCol"><span class="emptyPoint"></span></div>
          <div class="normalCol">&pound;{{ order.order_total }}</div>
          <div class="normalCol">{{ order.created_at | formatDate }}</div>
          <div class="normalCol">{{ order.updated_at | formatDate }}</div>
        </div>
      </div>
      </router-link>
    </div>
  </div>
  <div class="paginationContainer">
    <div class="row">
      <div class="col-sm-12">
        <div align="right">
          Showing page {{ pagination.current_page }} of {{ pagination.last_page }} <a class="btnPaginate" @click.prevent="getOrders(pagination.prev_page_url)">Previous</a> <a class="btnPaginate" @click.prevent="getOrders(pagination.next_page_url)">Next</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      orders: {},
      pagination: {}
    }
  },
  created () {
    this.getOrders()
  },
  methods: {
    getOrders (page) {
      if (typeof page === 'undefined') {
        page = `/companies/${this.$route.params.id}/orders?page=1`
      }
      axios
        .get(page)
        .then((res) => {
          this.orders = res.data.data
          this.pagination = res.data
        })
    }
  }
}
</script>
