<template>
  <div class="app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px; padding-right: 0px;">
                <left-nav></left-nav>
                 <div id="appContainer">
                   <header class="titleRow">
                    <div class="row">
                      <div class="col-sm-8">
                          <h4><router-link :to="{name: 'Products'}">Products</router-link> / <router-link :to="{name: 'ProductsView', params: { id: $route.params.id }}">{{ product.name }}</router-link></h4>
                      </div>
                      <div class="col-sm-4">
                        <div align="right">
                          <!--<a @click.prevent="printLocationLabel()" class="btnGreen" style="margin-top: -3px; margin-right: 15px;">Print Label</a>-->
                          <a :href="'https://192.168.1.160/basco/location-label-print.php?pid='+ this.$route.params.id + ''" target="_blank" class="btnGreen" style="margin-top: -3px; margin-right: 15px;">Print Label</a>
                          <a @click.prevent="deleteProduct()" class="btnRedHeader" style="margin-top: -3px;">Delete Product</a>
                        </div>
                      </div>
                    
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <ul class="filterNav filterNavBottom">
                          <!--<router-link :to="{name: 'ProductsView', params: { id: $route.params.id }}"><li>Variants</li></router-link>-->
                          <router-link :to="{name: 'ProductsViewEdit', params: { id: $route.params.id }}"><li>Details</li></router-link>
                        </ul>
                      </div>
                    </div>
                  </header>
                  <app-content></app-content>
                 </div>
             </div>
        </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import LeftNav from '@/components/nav/Products.vue'
import AppContent from '@/views/products/ProductEdit.vue'

import axios from 'axios'

export default {
  // name: 'Home',
  components: {
    LeftNav,
    AppContent
  },
  data () {
    return {
      product: {}
    }
  },
  created () {
    axios
      .get(`/products/${this.$route.params.id}`)
      .then((res) => {
        this.product = res.data
      })
  },
  methods: {
    deleteProduct () {
      axios
        .delete(`/products/${this.$route.params.id}`, this.product)
        .then(response => (
          this.$toast.success("Product deleted", {}),
          this.$router.push({ name: 'Products', params: { id: this.$route.params.id } })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    printLocationLabel () {
      this.$toast.success("Label printed", {})
      axios
        .get(`https://192.168.1.160/basco/location-label-print.php?pid=${this.$route.params.id}`)
        .then(response => (
          this.$toast.success("Label printed", {})
        ))
        .catch(err => console.log(err))
    }
  }
}
</script>
