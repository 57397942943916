<template>
  <div id="modalOverlay">
      <div id="modalWindow">
        <div class="row">
          <div class="col-sm-12" style="margin-bottom: 15px;">
            <h3>Add New Address</h3>
          </div>
        </div>
        <form @submit.prevent="addAddress">
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-4">
                <label>Label</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="address.label" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Line 1</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="address.address1" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Line 2</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="address.address2" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>City</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="address.city" />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-4">
                <label>County</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="address.county" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Post Code</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="address.postcode" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Country</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="address.country" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <input type="submit" value="Create Address" />
              </div>
            </div>
          </div>
        </div>
        </form>
        <router-link :to="{name: 'CustomersAddress', params: { id: $route.params.id }}"><font-awesome-icon icon="plus" class="modalClose" /></router-link>
      </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      address: {}
    }
  },
  methods: {
    addAddress () {
      this.address.company_id = this.$route.params.id
      axios
        .post('/addresses', this.address)
        .then(response => (
          this.$toast.success("Address created", {}),
          this.$router.push({ name: 'CustomersAddress', params: { id: this.$route.params.id } })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }
  }
}
</script>
