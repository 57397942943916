<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="normalCol">Label</div>
          <div class="normalCol">Address</div>
          <div class="normalCol">Street</div>
          <div class="normalCol">City</div>
          <div class="normalCol">Country</div>
          <div class="normalCol">Post Code</div>
        </div>
      </div>
    </div>
    <div class="row" v-for="address in addresses" :key="address.id">
      <router-link class="resultLink" :to="{name: 'CustomersAddressesEdit', params: { id: $route.params.id, aid: address.id }}">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol">
            <div align="center">
              <input type="checkbox" name="checkbox" />
            </div>
          </div>
          <div class="normalCol">{{ address.label }}</div>
          <div class="normalCol">{{ address.address1 }}</div>
          <div class="normalCol">{{ address.address2 }}</div>
          <div class="normalCol">{{ address.city }}</div>
          <div class="normalCol">{{ address.county }}</div>
          <div class="normalCol">{{ address.postcode }}</div>
        </div>
      </div>
      </router-link>
    </div>
    <div class="row" id="resultsFooterLink">
      <div class="col-sm-12">
        <div align="center">
          <router-link :to="{name: 'CustomersAddressNew', params: { id: $route.params.id }}"><font-awesome-icon icon="plus" /> Add New Address</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      addresses: []
    }
  },
  created () {
    axios
      .get(`/companies/${this.$route.params.id}/addresses`)
      .then(response => {
        this.addresses = response.data
      })
  },
  methods: {
  }
}
</script>
