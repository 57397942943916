<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="doubleCol">Name</div>
          <div class="normalCol">SKU</div>
          <div class="normalCol">Price</div>
          <div class="normalCol">Last Updated</div>
        </div>
      </div>
    </div>
    <div class="row" v-for="pricelist in pricelists" :key="pricelist.id">
      <router-link class="resultLink" :to="{name: 'ProductsVariantsEdit', params: { id: pricelist.product_id, vid: pricelist.variant_id }}">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol">
            <div align="center">
              <input type="checkbox" name="checkbox" />
            </div>
          </div>
          <div class="doubleCol">{{ pricelist.name }}</div>
          <div class="normalCol">{{ pricelist.sku }}</div>
          <div class="normalCol">&pound;{{ pricelist.price }}</div>
          <div class="normalCol">{{ pricelist.last_updated | formatDate }}</div>
        </div>
      </div>
      </router-link>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      pricelists: []
    }
  },
  created () {
    axios
      .get(`/companies/${this.$route.params.id}/pricelists`)
      .then(response => {
        this.pricelists = response.data
      })
  },
  methods: {
  }
}
</script>
