<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px; margin-top: 20px;">
    <div class="row">
      <div class="col-sm-4">
        <div class="itemsGroup">
          <div class="itemsList">
            <dt class="item">Reason</dt>
            <dd class="item">
              {{ stockadjustment.reason }}
            </dd>
          </div>
        </div>
      </div>
      <div class="col-sm-8"></div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="pageContainer">
          <div class="row">
            <div class="col-sm-12">
              <table class="orderItem">
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th style="width: 9%;">Quantity to Change</th>
                    <th style="width: 16%;">Cost per Item</th>
                    <th style="width: 16%;">Suplier Code</th>
                    <th style="width: 16%;">Available</th>
                    <th style="width: 16%;">On Hand</th>
                    <th style="width: 10px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="stockadjustmentitem in stockadjustmentitems" :key="stockadjustmentitem.id">
                    <td>{{ stockadjustmentitem.name }} ({{ stockadjustmentitem.sku }})</td>
                    <td>{{ stockadjustmentitem.quantity }}</td>
                    <td>&pound;{{ stockadjustmentitem.value }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <textarea v-model="stockadjustment.notes" placeholder="Notes" rows="6"></textarea>
            </div>
            <div class="col-sm-1">
            </div>
            <div class="col-sm-3">
              <div class="row">
                <div class="col-sm-6">
                  Total Units
                </div>
                <div class="col-sm-6">
                  <strong>{{ stockadjustment.quantity }}</strong>
                </div>
              </div>
              <div class="row" style="margin-top:5px;">
                <div class="col-sm-6">
                  Total Value
                </div>
                <div class="col-sm-6">
                  <strong>&pound;{{ stockadjustment.total_value.toFixed(2) }}</strong>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div align="right">
                <input type="submit" value="Save Adjustment" @click.prevent="saveStockAdjustment()">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      stockadjustmentitems: [],
      stockadjustment: []
    }
  },
  created () {
    axios
      .get(`/stockadjustments/${this.$route.params.id}`)
      .then(response => {
        this.stockadjustment = response.data
      })
    axios
      .get(`/stockadjustments/${this.$route.params.id}/items`)
      .then(response => {
        this.stockadjustmentitems = response.data
      })
  },
  methods: {
    saveStockAdjustment () {
      axios
        .put(`/stockadjustments/${this.$route.params.id}`, this.stockadjustment)
        .then(response => {
          this.$router.push({ name: 'StockAdjustments', data: response })
        })
        .catch(err => console.log(err))
    }
  }
}
</script>
