<template>
  <div class="home">
    <div class="container-fluid" style="background: #000000; min-height: 100vh;">
      <div class="container">
        <div class="row">
          <div class="col-md-4">

          </div>
          <div class="col-md-4">
            <div class="row" style="margin-top: 25%; margin-bottom: 30px;">
              <div class="col-sm-12">
                <div align="center">
                  <img alt="Logo" src="../assets/images/logo.svg" width="50%" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="bascoLoginContainer">
                  <form @submit.prevent="login">
                  <div class="row">
                    <div class="col-sm-12">
                      <input type="email" v-model="form.email" class="form-control" id="email" placeholder="Account Email" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <input type="password" v-model="form.password" class="form-control" id="password" placeholder="Password" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <button type="submit">
                        Login
                      </button>
                    </div>
                  </div>
                  </form>
                  <!--<div class="row">
                    <div class="col-sm-12">
                      <div align="right">
                        <router-link to="/dashboard" class="bascoLink" style="color: #FFFFFF;">Forgot your password?</router-link>
                      </div>
                    </div>
                  </div>-->
                  <div class="row" style="margin-top: 30px; margin-bottom: 50px;">
                    <div class="col-sm-1">
                    </div>
                    <div class="col-sm-10">
                      <p style="text-align: center; color: #FFFFFF;">By clicking on 'Login' you agree to our <a href="https://www.bascofinefoods.com/trade-terms-conditions/" target="_blank" style="vertical-align: top; color: #FFFFFF; text-decoration: underline;">Terms and Conditions</a>, and have read and acknowledged our <a href="https://www.bascofinefoods.com/privacy-policy/" target="_blank" style="vertical-align: top; color: #FFFFFF; text-decoration: underline;">Privacy Statement.</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";
import axios from 'axios'
import '@/assets/css/login.css'
import { mapActions } from 'vuex'
export default {
   data() {
      return {
         form: {
            email: '',
            password: ''
         }
      }
   },
methods: {
      async login() {
        store.dispatch('login', this.form);
      }
   }
}
</script>
