<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <header class="titleRow">
      <div class="row">
        <div class="col-sm-4">
            <h4>Batch Tracking</h4>
        </div>
        <div class="col-sm-5">
          <input type="text" v-model="keywordSearch" v-on:keyup.enter="searchProducts" placeholder="Search by lot, sku, product ..." />
        </div>
        <div class="col-sm-2">
          <div align="right">
            <router-link to="/batch/new" class="btnGreenHeader" style="margin-top: -3px;">Create New</router-link>
          </div>
        </div>
        <div class="col-sm-1">
            
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <ul class="filterNav filterNavBottom">
            <li class="active">All</li>
          </ul>
        </div>
      </div>
    </header>
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="quadCol">Product</div>
          <div class="doubleCol">SKU</div>
          <div class="doubleCol">Lot Number</div>
          <div class="normalCol">Last Updated</div>
        </div>
      </div>
    </div>
    <div class="row" v-for="batch in batches" :key="batch.id">
      <router-link class="resultLink" :to="{name: 'ProductsView', params: { id: batch.id }}">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol">
            <div align="center">
              <input type="checkbox" name="checkbox" />
            </div>
          </div>
          <div class="quadCol">{{ batch.product }}</div>
          <div class="doubleCol">{{ batch.sku }}</div>
          <div class="doubleCol">{{ batch.batch_number }}</div>
          <div class="normalCol">{{ batch.updated_at | formatDate }}</div>
        </div>
      </div>
      </router-link>
    </div>
  </div>
  <div class="paginationSpacer"></div>
  <div class="paginationContainer">
    <div class="row">
      <div class="col-sm-12">
        <div align="right">
          Showing page {{ pagination.current_page }} of {{ pagination.last_page }} <a class="btnPaginate" @click.prevent="getBatches(pagination.prev_page_url)">Previous</a> <a class="btnPaginate" @click.prevent="getProducts(pagination.next_page_url)">Next</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      keywordSearch: null,
      batches: [],
      pagination: {}
    }
  },
  created () {
    this.getBatches()
  },
  methods: {
    getBatches (page) {
      if (typeof page === 'undefined') {
        page = '/batch?page=1'
      }
      axios
        .get(page)
        .then((res) => {
          this.batches = res.data.data
          this.pagination = res.data
        })
    },
    searchBatches () {
      const keywords = { keywords: this.keywordSearch }
      axios
        .post('/batch/search', keywords)
        .then(response => {
          this.batches = response.data.data
          this.pagination = response.data
        })
    }
  }
}
</script>
