import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import router from '../router'

import createPersistedState from "vuex-persistedstate";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
     authenticated: false,
     user: null,
     token: null
  },
  mutations: {
     authenticateUser(state, data) {
        state.authenticated = true;
        state.token = data.data.token;
        state.user =  data.data.name;
        localStorage.setItem( 'token', data.data.token );
        localStorage.setItem( 'name', data.data.name );
     }
  },
  actions: {
     async login({ commit }, credentials) {
        await axios.get('sanctum/csrf-cookie');
        const res = await axios.post('login', credentials);
        commit('authenticateUser', res.data)
        router.push('/orders');
     }
  },
  getters: {
     isAuthenticated(state) {
        return state.authenticated;
     },
     user(state) {
        return state.user;
     },
     userToken(state) {
        return state.token;
     }
  },
  modules: {},
  plugins: [createPersistedState()]

})