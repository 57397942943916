<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <header class="titleRow">
      <div class="row">
        <div class="col-sm-4">
            <h4>Wholesale</h4>
        </div>
        <div class="col-sm-5">
          
        </div>
        <div class="col-sm-2">
        
        </div>
        <div class="col-sm-1">
            
        </div>
      </div>
    </header>
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Procure Wizard</h2>
                  </div>
                </div>
                <form method="post" action="https://admin.wholesale.bascofinefoods.com/scripts/procure-wizard.php" target="_blank">
                <input type="hidden" name="action" value="downloadPricelist" />
                <div class="row">
                  <div class="col-sm-4">
                    <label>Customer</label>
                  </div>
                  <div class="col-sm-8">
                    <div class="commerceSelect">
                      <select>
                        <option value="Absolute Tast">Absolute Tast</option>
                        <option value="Grantley Hall">Grantley Hall</option>
                        <option value="Rudding Park">Rudding Park</option>
                        <option value="JKS Restaurant">JKS Restaurant</option>
                        <option value="List Price">List Price</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    
                  </div>
                  <div class="col-sm-8">
                    <button type="submit" name="submit">Download</button>
                  </div>
                </div>
                </form>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      keywordSearch: null,
      companies: [],
      pagination: []
    }
  },
  created () {
  },
  /* watch: {
    keywordSearch (after, before) {
      this.searchCompanies()
    }
  }, */
  methods: {
  }
}
</script>
