<template>
    <div id="leftNavSecond">
        <nav><h5>{{ $route.meta.navName }}</h5></nav>
        <nav class="secondNav">
        <ul>
            <router-link to="/warehouse/stocktake"><li>Stocktake</li></router-link>
        </ul>
        </nav>
    </div>
</template>

<script>
export default {}
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
/*
export default {
  name: 'Home',
  components: {
    HelloWorld
  }
} */
</script>
