<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <header class="titleRow">
      <div class="row">
        <div class="col-sm-4">
            <h4>Wholesale</h4>
        </div>
        <div class="col-sm-5">
          
        </div>
        <div class="col-sm-2">
        
        </div>
        <div class="col-sm-1">
           
        </div>
      </div>
    </header>
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Top Bar</h2>
                  </div>
                </div>
                <form @submit.prevent="saveTopBar">
                <div class="row">
                  <div class="col-sm-4">
                    <label>Enabled</label>
                  </div>
                  <div class="col-sm-8">
                    <label class="checkboxContainer">
                      <input type="checkbox" v-model="topbarenabled.content">
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Content</label>
                  </div>
                  <div class="col-sm-8">
                    <textarea rows="6" v-model="topbarcontent.content"></textarea>
                  </div>
                </div>
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    
                  </div>
                  <div class="col-sm-8">
                    <button type="submit" name="submit">Save</button>
                  </div>
                </div>
                </form>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      topbarenabled: [],
      topbarcontent: []
    }
  },
  
  created () {
    axios
      .get(`/wholesaleplatform/topbarenabled`)
      .then((res) => {
        this.topbarenabled = res.data
      })
      axios
      .get(`/wholesaleplatform/topbarcontent`)
      .then((res) => {
        this.topbarcontent = res.data
      })
  },
  methods: {
    saveTopBar () {
      axios
        .put(`/wholesaleplatform/updatetopbarenabled`, this.topbarenabled)
        .then(response => (
          this.$router.push({ name: 'WholesaleTopBar' })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
      axios
        .put(`/wholesaleplatform/updatetopbarcontent`, this.topbarcontent)
        .then(response => (
          this.$router.push({ name: 'WholesaleTopBar' })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
        this.$toast.success("Top Bar saved", {});  
    }
  }
}
</script>
