<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="normalCol">Date</div>
          <div class="trippleCol">Reference</div>
          <div class="normalCol">Status</div>
        </div>
      </div>
    </div>

    <div class="row" v-for="stocktake in stocktakes" :key="stocktake.id">
      <router-link :to="{name: 'StockTakeView', params: { id: stocktake.id }}" style="width: 100%;">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol"></div>
          <div class="normalCol">{{ stocktake.created_at | formatDate }}</div>
          <div class="trippleCol">{{ stocktake.reference }}</div>
          <div class="normalCol"><span class="itemStatus" :class="stocktake.status">{{ stocktake.status }}</span></div>
        </div>
      </div>
      </router-link>
    </div>

    <div class="paginationContainer">
      <div class="row">
        <div class="col-sm-12">
          <div align="right">
            Showing page {{ pagination.current_page }} of {{ pagination.last_page }} <a class="btnPaginate">Previous</a> <a class="btnPaginate">Next</a>
          </div>
        </div>
      </div>
    </div>
  
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      stocktakes: {},
      pagination: {}
    }
  },
  created () {
    this.getStockTakes()
  },
  methods: {
    getStockTakes (page) {
      if (typeof page === 'undefined') {
        page = '/stocktakes?page=1'
      }
      axios
        .get(page)
        .then((res) => {
          this.stocktakes = res.data.data
          this.pagination = res.data
        })
    }
  }
}
</script>