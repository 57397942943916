<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"><!--<input type="checkbox" name="checkbox" @click="selectAll" v-model="allSelected" style="margin-left:20px;" />--></div>
          <div class="trippleCol">Product</div>
          <div class="smallCol">SKU</div>
          <div class="smallCol">Count</div>
          <div class="smallCol">SOH</div>
          <div class="smallCol">Variance</div>
          <div class="normalCol">Status</div>
          <div style="display: inline-block;"><a class="btnGreenHeader" @click="addRecount()" style="margin-top: -3px;">Recount</a></div>
        </div>
      </div>
    </div>

    <div class="row" v-for="product in products" :key="product.id">
      <div class="col-sm-12">
        <div id="resultsBody" v-bind:class="(product.recount_number == category.recount_number)?'highlight':''">
          <div class="checkboxCol">
            <input type="checkbox" name="checkbox" :value="product.id" v-model="recounts" style="margin-left:20px;" />
          </div>
          <div class="trippleCol">{{ product.product_name }}</div>
          <div class="smallCol">{{ product.sku }}</div>
          <div class="smallCol">{{ product.count }}</div>
          <div class="smallCol">{{ product.stock_on_hand }}</div>
          <div class="smallCol">{{ (product.count-product.stock_on_hand) }}</div>
          <div class="normalCol"><span class="itemStatus" :class="product.status">{{ product.status +' '+product.recount_number }}</span></div>
          <div class="normalCol"><textarea rows="2" v-on:blur="saveMessage(product.id, product.message)" v-model="product.message"></textarea></div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>

import axios from 'axios'

export default {
  components: {  },
  data () {
    return {
      products: [],
      stocktake: [],
      recounts: [],
      category: [],
      allSelected: false,
    }
  },
  created () {
    axios
      .get(`/stocktakes/categories/${this.$route.params.id}`)
      .then(response => {
        this.products = response.data
      }),
    axios
      .get(`/stocktakes/categories/${this.$route.params.id}/summary`)
      .then(response => {
        this.category = response.data
      })
  },
  methods: {
    /*
    selectAll: function() {
        this.recounts = [];

        if (this.allSelected) {
            for (product in this.products) {
                this.recounts.push(this.products[product].id.toString());
            }
        }

    },
    */
    refreshCategory () {
      axios
        .get(`/stocktakes/categories/${this.$route.params.id}`)
        .then(response => {
          this.products = response.data
        })
    },
    saveMessage (productid, message) {

      const postdata = {
        message: message,
      }
      axios
        .post(`/stocktakes/stocktakeitem/${productid}`, postdata)
        .then(response => (
          this.refreshCategory(),
          this.$toast.success("Message saved", {})
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)

    },
    /*
    onReconciling(itemid) {
      const postdata = {
        status: 'Recount',
        itemid: itemid
      }
      axios
        .put(`/stocktakes/categories/${this.$route.params.id}`, postdata)
        .then(response => (
          this.refreshCategory(),
          this.$toast.success("Recount set", {})
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    */
    addRecount() {
      const postdata = {
        status: 'Recount',
        items: this.recounts,
        recount_number: (this.category.recount_number + 1),
      }
      axios
        .put(`/stocktakes/categories/${this.$route.params.id}`, postdata)
        .then(response => (
          this.refreshCategory(),
          this.$toast.success("Recount processed", {})
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
  }
}
</script>