<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px; margin-top: 20px;">
    <div class="row">
      <div class="col-sm-4">
        <div class="itemsGroup">
          <div class="itemsList">
            <dt class="item">Reason</dt>
            <dd class="item">
              <div class="commerceSelect">
                <select v-model="stockadjust.reason">
                  <option v-for="reason in reasons" :key="reason">{{ reason }}</option>
                </select>
              </div>
            </dd>
          </div>
        </div>
      </div>
      <div class="col-sm-8"></div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="pageContainer">
          <div class="row">
            <div class="col-sm-12">
              <table class="orderItem">
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th style="width: 9%;">Quantity to Change</th>
                    <th style="width: 16%;">Cost per Item</th>
                    <th style="width: 16%;">Suplier Code</th>
                    <th style="width: 16%;">Available</th>
                    <th style="width: 16%;">On Hand</th>
                    <th style="width: 10px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="stockadjustmentitem, index in stockadjustmentitems" :key="stockadjustmentitem.id">
                    <td><VariantSelector v-model="stockadjustmentitem.variant_id" @input="updateVariant(index, stockadjustmentitem.variant_id)" /></td>
                    <td><input type="text" v-model="stockadjustmentitem.quantity" /></td>
                    <td>
                      <div class="inputTypeForUnitLeftOrder">
                        <input type="text" v-model="stockadjustmentitem.price" />
                        <div class="inputUnitLeftOrder">&pound;</div>
                      </div>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><div align="center" @click.prevent="deleteStockAdjustmentItem(index)"><img src="~@/assets/images/icon/remove.svg" width="10" /></div></td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="8">
                      <div class="addNewItemLink" @click.prevent="addNewItem()">
                        <font-awesome-icon icon="plus" /> Add another item
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <textarea v-model="stockadjust.notes" placeholder="Notes" rows="6"></textarea>
            </div>
            <div class="col-sm-8">
              <div align="right">
                <input type="submit" value="Create Stock Adjustment" @click.prevent="createStockAdjustment()">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

import VariantSelector from '@/components/form/VariantSelector.vue'

export default {
  components: { VariantSelector },
  data () {
    return {
      stockadjustmentitems: [],
      stockadjust: [],
      variantresponse: {},
      reasons: [
        'Basco Samples',
        'Damaged',
        'Damaged DPD',
        'Javier Samples',
        'Mis-Picked',
        'New Products',
        'Promotion',
        'Returned',
        'Shrinkage',
        'Stocktake',
        'Wastage'
      ]
    }
  },
  created () {
    this.stockadjustmentitems.push({})
  },
  watch: {
    'stockadjustmentitem.name': function (newVal, previousVal) {
      console.log('new Value is ' + newVal,
        'previous value is ' + previousVal)
    }
  },
  methods: {
    addNewItem () {
      this.stockadjustmentitems.push({})
    },
    deleteStockAdjustmentItem (id) {
      this.$delete(this.stockadjustmentitems, id)
    },
    createStockAdjustment () {
      const newstockadjustment = {
        stockadjustmentitems: this.stockadjustmentitems,
        stockreason: this.stockadjust.reason,
        notes: this.stockadjust.notes
      }
      axios
        .post('/stockadjustments', newstockadjustment)
        .then(response => {
          this.$router.push({ name: 'StockAdjustments' })
        })
        .catch(err => console.log(err))
    },
    updateVariant (index, variant) {
      axios
        .get(`/variants/${variant}`)
        .then(response => {
          this.variantresponse = response.data
          this.stockadjustmentitems[index].price = this.variantresponse.buy_eur
        })
        .catch(err => console.log(err))
    }
  }
}
</script>
