<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <header class="titleRow">
      <div class="row">
        <div class="col-sm-4">
            <h4>Wholesale</h4>
        </div>
        <div class="col-sm-5">
          
        </div>
        <div class="col-sm-2">
        
        </div>
        <div class="col-sm-1">
            
        </div>
      </div>
    </header>
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Documents</h2>
                  </div>
                </div>
                <form @submit.prevent="saveDocuments" enctype="multipart/form-data">
                <div class="row">
                  <div class="col-sm-4">
                    <label>Brochure</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="file" v-on:change="onBrochureChange" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Pricelist</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="file" v-on:change="onPriceListChange" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Allergens</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="file" v-on:change="onAllergensChange" />
                  </div>
                </div>
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    
                  </div>
                  <div class="col-sm-8">
                    <button type="submit" name="submit">Save Documents</button>
                  </div>
                </div>
                </form>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      brochure: '',
      pricelist: '',
      allergens: ''
    }
  },
  created () {
  },
  methods: {
    onBrochureChange(e){
        this.brochure = e.target.files[0];
    },
    onPriceListChange(e){
        this.pricelist = e.target.files[0];
    },
    onAllergensChange(e){
        this.allergens = e.target.files[0];
    },
    saveDocuments(e) {
        e.preventDefault();

        let currentObj = this;
   
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        let formData = new FormData();
        formData.append('brochure', this.brochure);
        formData.append('pricelist', this.pricelist);
        formData.append('allergens', this.allergens);

        axios.post('/wholesaleplatform/uploaddocuments', formData, config)
        .then(function (response) {
          
        })
        .catch(function (error) {
          console.log(error);
          
        });
        this.$toast.success("Documents saved", {});
    }
  }
}
</script>
