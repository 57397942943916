<template>
  <div class="app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px; padding-right: 0px;">
                <left-nav></left-nav>
                 <div id="appContainer">
                  <app-content></app-content>
                 </div>
             </div>
        </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import LeftNav from '@/components/nav/Customers.vue'
import AppContent from '@/views/customers/Customers.vue'

export default {
  data () {
    return {
      keywordSearch: null
    }
  },
  // name: 'Home',
  components: {
    LeftNav,
    AppContent
  }
}
</script>
