<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-12">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-9">
                <div class="pageCard">
                  <div class="row">
                    <div class="col-sm-4">
                      <v-select v-model="order.company_id" :options="companies" :reduce="companies => companies.id" label="name" />
                      <!--
                        <div class="commerceSelect">
                        <select v-model="order.company_id">
                          <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                        </select>
                      </div>-->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4"></div>
                      <div class="col-sm-4">
                        <div class="row">
                          <div class="col-sm-4">
                            <label>Delivery Date</label>
                          </div>
                          <div class="col-sm-8">
                            <date-picker
                              v-model="order.ship_at"
                              format="DD/MM/YYYY"
                              type="date"
                              placeholder="Select date"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-4">
                            <label>Reference</label>
                          </div>
                          <div class="col-sm-8">
                            <input type="text" class="text" v-model="order.customer_reference" />
                          </div>
                        </div>
                      </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="col-sm-4">
                          <label>Currency</label>
                        </div>
                        <div class="col-sm-8">
                          <div class="commerceSelect">
                            <select v-model="order.currency">
                              <option value="GBP">British Pound (GBP)</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <table class="orderItem">
                        <thead>
                          <tr>
                            <th>Item Name</th>
                            <th style="width: 9%;">Quantity</th>
                            <th style="width: 9%;">Available</th>
                            <th style="width: 16%;">Price (GBP)</th>
                            <th style="width: 80px;">Discount</th>
                            <th style="width: 80px;">VAT</th>
                            <th style="width: 12%;">Total (GBP)</th>
                            <th style="width: 30px;"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <!--<v-select @input="createOrder()" v-model="order.variant" :options="variants" :reduce="variants => variants.id" label="name" />-->
                              <input type="text" class="orderProductInput" v-model="productInput" @focus="onFocus" @blur="onBlur" />
                              <div class="inputResults" v-show="isVisible">
                                <ul>
                                  <li v-for="product in products" :key="product.id" @click.prevent="createOrder(product.id)">
                                    <div class="row">
                                      <div class="col-sm-1">
                                        <img :src="product.image" width="100%" />
                                      </div>
                                      <div class="col-sm-7">
                                        {{product.name}}<br /><span>{{product.sku}}</span>
                                      </div>
                                      <div class="col-sm-4">
                                        Available: {{product.available}}<br />
                                        <span>Allocated: {{product.allocated}}<br />
                                        Stock on Hand: {{product.stock_on_hand}}<br />
                                        On Purchase: {{product.on_purchase}}<br /></span>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-sm-12">
                                        {{ product.notes }}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </td>
                            <td><input type="text" name="" value="" /></td>
                            <td></td>
                            <td>
                              <div class="inputTypeForUnitLeftOrder">
                                
                              </div>
                            </td>
                            <td>
                              <div class="inputTypeForUnitOrder">
                               
                              </div>
                            </td>
                            <td>
                              <div class="inputTypeForUnitOrder">
                                
                              </div>
                            </td>
                            <td></td>
                            <td><div align="center"><img src="~@/assets/images/icon/remove.svg" width="10" /></div></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="8">
                              <div class="addNewItemLink">
                                <font-awesome-icon icon="plus" /> Add another item
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <textarea v-model="order.notes" rows="5" placeholder="Order notes"></textarea>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-5">
                      <div class="row">
                        <div class="col-sm-6">
                          Total Units
                        </div>
                        <div class="col-sm-6">
                          0
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          Total
                        </div>
                        <div class="col-sm-6">
                          &pound;0.00
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-1">
                      <!--<label style="text-align: left;">Tags</label>-->
                    </div>
                    <div class="col-sm-3">
                      
                    </div>
                    <div class="col-sm-8">
                      <div align="right">
                        <input type="submit" value="Create Order">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="pageSubContainer" style="padding: 0px 0px 15px 0px;">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="pageCard"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import axios from 'axios'

export default {
  components: { DatePicker },
  data () {
    return {
      companies: [],
      productInput: null,
      isVisible: false,
      products: [],
      order: []
    }
  },
  created () {
    axios
      .get('/companies/companies_list?type=business')
      .then(response => {
        this.companies = response.data
      })
    /*axios
      .get('/api/variants/list')
      .then(response => {
        this.variants = response.data
      })*/
  },
  watch: {
    productInput (after, before) {
      if (after === null || before === null) {
        this.DoNothing()
      } else {
        this.searchProduct()
      }
    }
  },
  methods: {
    onFocus: function () {
      this.isVisible = true
      this.searchProduct()
    },
    onBlur: function () {
      //this.isVisible = false
    },
    searchProduct () {
      const keywords = { keywords: this.productInput }
      this.isVisible = true
      axios
        .post('/products/search', keywords)
        .then(response => {

          this.products = response.data.data
        })
    },
    createOrder (product) {
      const neworder = {
        variant_id: product,
        company_id: this.order.company_id,
        currency: 'GBP',
        //user: this.state.user
      }
      axios
        .post('/orders/new', neworder)
        .then(response => {
          this.$router.push({ name: 'OrdersEdit', params: { id: response.data.id } })
        })
    }
  }
}
</script>
