<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="quadCol">Note</div>
          <div class="normalCol">Added by</div>
          <div class="normalCol">Added</div>
          <div class="checkboxCol"></div>
        </div>
      </div>
    </div>
    <div class="row" v-for="note in notes" :key="note.id">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol">
            <div align="center">
              <input type="checkbox" name="checkbox" />
            </div>
          </div>
          <div class="quadCol">{{ note.note }}</div>
          <div class="normalCol">{{ note.user_name }}</div>
          <div class="normalCol">{{ note.created_at | formatDate }}</div>
          <div class="checkboxCol"><div align="center"><img src="~@/assets/images/icon/remove.svg" width="10" @click.prevent="deleteNote(note.id)" /></div></div>
        </div>
      </div>
    </div>
    <div class="row" v-if="newnote">
      <div class="col-sm-12">
        <form @submit.prevent="createNote">
        <div id="resultsBody">
          <div class="checkboxCol"></div>
          <div class="quadCol"><input type="text" v-model="newnotecontent.note" /></div>
          <div class="normalCol"></div>
          <div class="normalCol"></div>
          <div class="checkboxCol"></div>
        </div>
      </form>
      </div>
    </div>
    <div class="row" id="resultsFooterLink">
      <div class="col-sm-12">
        <div align="center">
          <a @click="newnote = !newnote"><font-awesome-icon icon="plus" /> Add New Note</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      notes: [],
      newnote: false,
      newnotecontent: []
    }
  },
  created () {
    axios
      .get(`/companies/${this.$route.params.id}/notes`)
      .then(response => {
        this.notes = response.data
      })
  },
  methods: {
    createNote () {
      // this.newnote.company_id = this.$route.params.id
      const notecreate = {
        company_id: this.$route.params.id,
        note: this.newnotecontent.note
      }
      axios
        .post(`/companies/${this.$route.params.id}/notes`, notecreate)
        .then(response => (
          this.$toast.success("Note added", {}),
          this.$router.push({ name: 'CustomersNotes', params: { id: this.$route.params.id } })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    },
    deleteNote (id) {
      const notedelete = {
        id: id
      }
      axios
        .delete(`/companies/${this.$route.params.id}/notes`, notedelete)
        .then(response => (
          this.$toast.success("Note deleted", {}),
          this.$router.push({ name: 'CustomersNotes', params: { id: this.$route.params.id } })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }
  }
}
</script>
