<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <header class="titleRow">
      <div class="row">
        <div class="col-sm-4">
            <h4>Wholesale Menu Banners</h4>
        </div>
        <div class="col-sm-5">
          
        </div>
        <div class="col-sm-2">
        
        </div>
        <div class="col-sm-1">
            
        </div>
      </div>
    </header>
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <form @submit.prevent="saveBanners" enctype="multipart/form-data">
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Food</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Image</label>
                  </div>
                  <div class="col-sm-8">
                    <img :src="food_image_display.content" width="200" />
                    <input type="file" v-on:change="onFoodChange" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Title</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="foodtitle.content" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Link Text</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="foodlinktext.content" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Link</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="foodlink.content" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Drink</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Image</label>
                  </div>
                  <div class="col-sm-8">
                    <img :src="drink_image_display.content" width="200" />
                    <input type="file" v-on:change="onDrinkChange" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Title</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="drinktitle.content" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Link Text</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="drinklinktext.content" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Link</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="drinklink.content" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Kitchenware</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Image</label>
                  </div>
                  <div class="col-sm-8">
                    <img :src="kitchen_image_display.content" width="200" />
                    <input type="file" v-on:change="onKitchenChange" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Title</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="kitchentitle.content" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Link Text</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="kitchenlinktext.content" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Link</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="kitchenlink.content" />
                  </div>
                </div>
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    
                  </div>
                  <div class="col-sm-8">
                    <button type="submit" name="submit">Save Banners</button>
                  </div>
                </div>
              </form>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
  </div>
  
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      food_image_display: '',
      drink_image_display: '',
      kitchen_image_display: '',
      food_image: '',
      foodtitle: '',
      foodlinktext: '',
      foodlink: '',
      drink_image: '',
      drinktitle: '',
      drinklinktext: '',
      drinklink: '',
      kitchen_image: '',
      kitchentitle: '',
      kitchenlinktext: '',
      kitchenlink: ''
    }
  },
  created () {
    axios
      .get(`/wholesaleplatform/menubanners/6`)
      .then((res) => {
        this.food_image_display = res.data
      })
    axios
      .get(`/wholesaleplatform/menubanners/7`)
      .then((res) => {
        this.foodtitle = res.data
      })
    axios
      .get(`/wholesaleplatform/menubanners/8`)
      .then((res) => {
        this.foodlink = res.data
      }) 
    axios
      .get(`/wholesaleplatform/menubanners/9`)
      .then((res) => {
        this.foodlinktext = res.data
      }) 
    axios
      .get(`/wholesaleplatform/menubanners/10`)
      .then((res) => {
        this.drink_image_display = res.data
      }) 
    axios
      .get(`/wholesaleplatform/menubanners/11`)
      .then((res) => {
        this.drinktitle = res.data
      }) 
    axios
      .get(`/wholesaleplatform/menubanners/12`)
      .then((res) => {
        this.drinklink = res.data
      }) 
    axios
      .get(`/wholesaleplatform/menubanners/13`)
      .then((res) => {
        this.drinklinktext = res.data
      }) 
    axios
      .get(`/wholesaleplatform/menubanners/14`)
      .then((res) => {
        this.kitchen_image_display = res.data
      }) 
    axios
      .get(`/wholesaleplatform/menubanners/15`)
      .then((res) => {
        this.kitchentitle = res.data
      }) 
    axios
      .get(`/wholesaleplatform/menubanners/16`)
      .then((res) => {
        this.kitchenlink = res.data
      }) 
    axios
      .get(`/wholesaleplatform/menubanners/17`)
      .then((res) => {
        this.kitchenlinktext = res.data
      }) 
    
  },
  methods: {
    onFoodChange(e){
        this.food_image = e.target.files[0];
    },
    onDrinkChange(e){
        this.drink_image = e.target.files[0];
    },
    onKitchenChange(e){
        this.kitchen_image = e.target.files[0];
    },
    saveBanners(e) {
        e.preventDefault();

        let currentObj = this;
   
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        let formData = new FormData();
        formData.append('food_image', this.food_image);
        formData.append('foodtitle', this.foodtitle.content);
        formData.append('foodlinktext', this.foodlinktext.content);
        formData.append('foodlink', this.foodlink.content);
        formData.append('drink_image', this.drink_image);
        formData.append('drinktitle', this.drinktitle.content);
        formData.append('drinklinktext', this.drinklinktext.content);
        formData.append('drinklink', this.drinklink.content);
        formData.append('kitchen_image', this.kitchen_image);
        formData.append('kitchentitle', this.kitchentitle.content);
        formData.append('kitchenlinktext', this.kitchenlinktext.content);
        formData.append('kitchenlink', this.kitchenlink.content);

        axios.post('/wholesaleplatform/menubanners', formData, config)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          
        });
        this.$toast.success("Banners saved", {});
}
  }
}
</script>
