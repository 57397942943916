<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-12">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-12">
                <div class="pageCard">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="itemsGroup">
                        <div class="itemsList">
                          <dt class="item">Company</dt>
                          <dd class="item">{{ order.company_id }}</dd>
                        </div>
                      </div>
                    </div>
                      <div class="col-sm-4">
                        <div class="itemsGroup">
                          <div class="itemsList">
                            <dt class="item">Issue Date</dt>
                            <dd class="item">{{ order.issued_at | formatDate }}</dd>
                          </div>
                          <div class="itemsList">
                            <dt class="item">Delivery Date</dt>
                            <dd class="item">{{ order.due_at | formatDate }}</dd>
                          </div>
                          <div class="itemsList">
                            <dt class="item">Payment Date</dt>
                            <dd class="item">{{ order.payment_due_at | formatDate }}</dd>
                          </div>
                          <div class="itemsList">
                            <dt class="item">Reference</dt>
                            <dd class="item">{{ order.reference }}</dd>
                          </div>
                        </div>
                      </div>
                    <div class="col-sm-4">
                      <div class="itemsGroup">
                        <div class="itemsList">
                          <dt class="item">Currency</dt>
                          <dd class="item">{{ order.currency }}</dd>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <table class="orderItem">
                        <thead>
                          <tr>
                            <th>Item Name</th>
                            <th style="width: 9%;">Quantity</th>
                            <th style="width: 16%;">Cost per unit</th>
                            <th style="width: 80px;">VAT</th>
                            <th style="width: 12%;">Total</th>
                            <th style="width: 30px;"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="purchaseitem in purchaseitems" :key="purchaseitem.id">
                            <td>{{ purchaseitem.name }} ({{ purchaseitem.sku }})</td>
                            <td>{{ purchaseitem.quantity }}</td>
                            <td>
                                <div v-if="order.currency === 'GBP'">&pound;{{ purchaseitem.unit_cost.toFixed(2) }}</div>
                                <div v-if="order.currency === 'EUR'">&euro;{{ purchaseitem.unit_cost.toFixed(2) }}</div>
                            </td>
                            <td>{{ purchaseitem.vat }}%</td>
                            <td>
                              <div v-if="order.currency === 'GBP'">&pound;{{ purchaseitem.total_cost.toFixed(2) }}</div>
                              <div v-if="order.currency === 'EUR'">&euro;{{ purchaseitem.total_cost.toFixed(2) }}</div>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <table class="orderItem">
                        <thead>
                          <tr>
                            <th colspan="6">Landing Costs</th>
                          </tr>
                          <tr>
                            <th>Landed Costs</th>
                            <th style="width: 16%;">Costs</th>
                            <th style="width: 80px;">VAT</th>
                            <th style="width: 12%;">Total</th>
                            <th style="width: 30px;"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="landingcost in landingcosts" :key="landingcost.id">
                            <td>{{ landingcost.landing_cost_item }}</td>
                            <td>
                                <div v-if="order.currency === 'GBP'">&pound;{{ landingcost.unit_cost.toFixed(2) }}</div>
                                <div v-if="order.currency === 'EUR'">&euro;{{ landingcost.unit_cost.toFixed(2) }}</div>
                            </td>
                            <td>{{ landingcost.vat_percentage }}%</td>
                            <td>
                              <div v-if="order.currency === 'GBP'">&pound;{{ landingcost.total_cost.toFixed(2) }}</div>
                              <div v-if="order.currency === 'EUR'">&euro;{{ landingcost.total_cost.toFixed(2) }}</div>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                        <tfoot v-if="landingcostsedit">
                          <tr>
                            <td colspan="8">
                              <div class="addNewItemLink" @click.prevent="addNewLandingCostItem()">
                                <font-awesome-icon icon="plus" /> Add landing cost
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div class="row" style="margin-bottom: 15px;">
                    <div class="col-sm-12">
                      <a class="btnGreyLineSmall" @click="landingcostsedit = !landingcostsedit">Add Landed Costs</a>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                      <div class="commerceNotes">
                        <strong>NOTES:</strong><br /><br />
                        {{ order.notes }}
                      </div>
                    </div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-5">
                      <div class="row">
                        <div class="col-sm-6">
                          Items Subtotal
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'GBP'">
                          &pound;{{ order.vat }}
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'EUR'">
                          &euro;{{ order.vat }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          Items VAT
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'GBP'">
                          &pound;{{ order.vat }}
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'EUR'">
                          &euro;{{ order.vat }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          Landed Costs Subtotal
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'GBP'">
                          &pound;{{ order.vat }}
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'EUR'">
                          &euro;{{ order.vat }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          Landed Costs VAT
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'GBP'">
                          &pound;{{ order.vat }}
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'EUR'">
                          &euro;{{ order.vat }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <strong>Total</strong>
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'GBP'">
                          <strong>&pound;{{ order.total }}</strong>
                        </div>
                        <div class="col-sm-6" v-if="order.currency === 'EUR'">
                          <strong>&euro;{{ order.total }}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <hr />
                    </div>
                  </div>
                  <div class="row" v-if="landingcostsedit">
                    <div class="col-sm-12">
                      <div align="right">
                        <input type="submit" value="Save Landing Costs" @click.prevent="saveLandingCosts()">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  components: { },
  data () {
    return {
      companies: [],
      order: [],
      purchaseitems: [],
      landingcosts: [],
      landingcostsedit: false
    }
  },
  created () {
    axios
      .get(`/purchaseorders/${this.$route.params.id}`)
      .then(response => {
        this.order = response.data
      })
    axios
      .get(`/purchaseorders/${this.$route.params.id}/items`)
      .then(response => {
        this.purchaseitems = response.data
      })
    axios
      .get(`/purchaseorders/${this.$route.params.id}/landingcosts`)
      .then(response => {
        this.landingcosts = response.data
      })
  }
}
</script>
