import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/layouts/Login.vue'
import AppLayout from '@/layouts/App.vue'
import Toast from "vue-toastification";

import store from "@/store/index.js";

// Orders
import Orders from '@/layouts/orders/Orders.vue'
import OrdersView from '@/layouts/orders/OrdersView.vue'
import OrdersActive from '@/layouts/orders/OrdersActive.vue'
import OrdersPayment from '@/layouts/orders/OrdersPayment.vue'
import OrdersSending from '@/layouts/orders/OrdersSending.vue'
import OrdersNew from '@/layouts/orders/OrdersNew.vue'
import OrdersEdit from '@/layouts/orders/OrdersEdit.vue'

// Customers
import Customers from '@/layouts/customers/Customers.vue'
import CustomersOverview from '@/layouts/customers/CustomersOverview.vue'
import CustomersContactNew from '@/layouts/customers/CustomersNewContact.vue'
import CustomersContactEdit from '@/layouts/customers/CustomersEditContact.vue'
import CustomersAddresses from '@/layouts/customers/CustomersAddresses.vue'
import CustomersAddressesNew from '@/layouts/customers/CustomersAddressesNew.vue'
import CustomersAddressesEdit from '@/layouts/customers/CustomersAddressesEdit.vue'
import CustomersOrders from '@/layouts/customers/CustomersOrders.vue'
import CustomersNew from '@/layouts/customers/CustomersNew.vue'
import CustomersEditCompany from '@/layouts/customers/CustomersEdit.vue'
import CustomersPriceLists from '@/layouts/customers/CustomersPriceLists.vue'
import CustomersNotes from '@/layouts/customers/CustomersNotes.vue'

// Products
import Products from '@/layouts/products/Products.vue'
import ProductsNew from '@/layouts/products/ProductsNew.vue'
//import ProductsView from '@/layouts/products/ProductsView.vue'
import ProductsView from '@/layouts/products/ProductsEdit.vue'
import ProductsEdit from '@/layouts/products/ProductsEdit.vue'


// Batch
import Batch from '@/layouts/batch/Batch.vue'


// Wholesale
import Wholesale from '@/layouts/wholesale/Wholesale.vue'
//import Products from '@/layouts/wholesale/products/Products.vue'
//import ProductsNew from '@/layouts/wholesale/products/New.vue'
import WholesaleTopBar from '@/layouts/wholesale/WholesaleTopBar.vue'
import WholesaleDocuments from '@/layouts/wholesale/WholesaleDocuments.vue'
import WholesaleProcureWizard from '@/layouts/wholesale/WholesaleProcureWizard.vue'
import WholesaleHomeSliders from '@/layouts/wholesale/WholesaleHomeSliders.vue'
import WholesaleHomeSlidersNew from '@/layouts/wholesale/WholesaleHomeSlidersNew.vue'
import WholesaleHomeSlidersView from '@/layouts/wholesale/WholesaleHomeSlidersView.vue'
import WholesaleMenuBanners from '@/layouts/wholesale/WholesaleMenuBanners.vue'
import WholesalePopular from '@/layouts/wholesale/WholesalePopular.vue'


// Purchase Orders
import PurchaseOrders from '@/layouts/purchase-orders/PurchaseOrders.vue'
import PurchaseOrdersNew from '@/layouts/purchase-orders/New.vue'
import PurchaseOrdersView from '@/layouts/purchase-orders/View.vue'

// Stock Adjustments
import StockAdjustments from '@/layouts/stock-adjustments/StockAdjustments.vue'
import StockAdjustmentsNew from '@/layouts/stock-adjustments/New.vue'
import StockAdjustmentsView from '@/layouts/stock-adjustments/View.vue'

// Stock Takes
import Stocktakes from '@/layouts/stock-takes/Stocktakes.vue'


//Warehouse
import WarehouseStocktakes from '@/layouts/warehouse/stocktake/Stocktakes.vue'
import WarehouseStocktakeView from '@/layouts/warehouse/stocktake/View.vue'
import WarehouseStocktakeNew from '@/layouts/warehouse/stocktake/New.vue'
import WarehouseStocktakeViewCategory from '@/layouts/warehouse/stocktake/Category.vue'



Vue.use(Toast);
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { guestOnly: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      default: AppLayout
      // leftnav: LeftNav,
      // topnav: TopNav
    },
    meta: { requiresAuth: true }
    // component: AppLayout
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue')
  },
  {
    path: '/orders',
    params: { type: 'all'},
    name: 'Orders',
    component: Orders,
    meta: { requiresAuth: true, navName: 'Orders' }
  },
  {
    path: '/orders/active',
    params: { type: 'active'},
    name: 'OrdersActive',
    component: OrdersActive,
    meta: {requiresAuth: true, navName: 'Orders' }
  },
  {
    path: '/orders/payment',
    params: { type: 'payment'},
    name: 'OrdersPayment',
    component: OrdersPayment,
    meta: { requiresAuth: true, navName: 'Orders' }
  },
  {
    path: '/orders/sending',
    params: { type: 'sending'},
    name: 'OrdersSending',
    component: OrdersSending,
    meta: { requiresAuth: true, navName: 'Orders' }
  },
  {
    path: '/orders/:id',
    name: 'OrdersView',
    component: OrdersView,
    meta: { requiresAuth: true, navName: 'Orders' }
  },
  {
    path: '/orders/:id/edit',
    name: 'OrdersEdit',
    component: OrdersEdit,
    meta: { requiresAuth: true, navName: 'Orders' }
  },
  {
    path: '/orders/new',
    name: 'OrdersNew',
    component: OrdersNew,
    meta: { requiresAuth: true, navName: 'Orders' }
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/customers/new',
    name: 'CustomersNew',
    component: CustomersNew,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/customers/:id',
    name: 'CustomersEdit',
    component: CustomersOverview,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/customers/:id/edit',
    name: 'CustomersEditCompany',
    component: CustomersEditCompany,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/customers/:id/addresses',
    name: 'CustomersAddress',
    component: CustomersAddresses,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/customers/:id/addresses/new',
    name: 'CustomersAddressNew',
    component: CustomersAddressesNew,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/customers/:id/addresses/:aid',
    name: 'CustomersAddressesEdit',
    component: CustomersAddressesEdit,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/customers/:id/contact/new',
    name: 'CustomersContactNew',
    component: CustomersContactNew,
    meta: { authOnly: true, navName: 'Customers' }
  },
  {
    path: '/customers/:id/contact/:cid',
    name: 'CustomersContactEdit',
    component: CustomersContactEdit,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/customers/:id/notes',
    name: 'CustomersNotes',
    component: CustomersNotes,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/customers/:id/orders',
    name: 'CustomersOrders',
    component: CustomersOrders,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/customers/:id/pricelists',
    name: 'CustomersPriceLists',
    component: CustomersPriceLists,
    meta: { requiresAuth: true, navName: 'Customers' }
  },
  {
    path: '/products',
    params: { type: ''},
    name: 'Products',
    component: Products,
    meta: { requiresAuth: true, navName: 'Products' }
  },
  {
    path: '/products/new',
    params: { type: ''},
    name: 'ProductsNew',
    component: ProductsNew,
    meta: { requiresAuth: true, navName: 'Products' }
  },
  {
    path: '/products/:id',
    params: { type: ''},
    name: 'ProductsView',
    component: ProductsView,
    meta: { requiresAuth: true, navName: 'Products' }
  },
  {
    path: '/products/:id/edit',
    params: { type: ''},
    name: 'ProductsViewEdit',
    component: ProductsEdit,
    meta: { authOnly: true, navName: 'Products' }
  },
  {
    path: '/batch',
    params: { type: ''},
    name: 'Batch',
    component: Batch,
    meta: { requiresAuth: true, navName: 'Batch' }
  },
  {
    path: '/purchase-orders',
    name: 'PurchaseOrders',
    component: PurchaseOrders,
    meta: { authOnly: true, navName: 'Stock Control' }
  },
  {
    path: '/purchase-orders/new',
    name: 'PurchaseOrdersNew',
    component: PurchaseOrdersNew,
    meta: { authOnly: true, navName: 'Stock Control' }
  },
  {
    path: '/purchase-orders/:id',
    name: 'PurchaseOrdersView',
    component: PurchaseOrdersView,
    meta: { authOnly: true, navName: 'Stock Control' }
  },
  {
    path: '/stock-adjustments',
    name: 'StockAdjustments',
    component: StockAdjustments,
    meta: { authOnly: true, navName: 'Stock Control' }
  },
  {
    path: '/stock-adjustments/new',
    name: 'StockAdjustmentsNew',
    component: StockAdjustmentsNew,
    meta: { authOnly: true, navName: 'Stock Control' }
  },
  {
    path: '/stock-adjustments/:id',
    name: 'StockAdjustmentsView',
    component: StockAdjustmentsView,
    meta: { authOnly: true, navName: 'Stock Control' }
  },
  /*{
    path: '/stocktakes',
    name: 'Stocktakes',
    component: Stocktakes,
    meta: { authOnly: true, navName: 'Stock Control' }
  },*/
  {
    path: '/wholesale',
    params: { type: ''},
    name: 'Wholesale',
    component: Wholesale,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },
  {
    path: '/wholesale/topbar',
    params: { type: ''},
    name: 'WholesaleTopBar',
    component: WholesaleTopBar,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },
  {
    path: '/wholesale/documents',
    params: { type: ''},
    name: 'WholesaleDocuments',
    component: WholesaleDocuments,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },
  {
    path: '/wholesale/procurewizard',
    params: { type: ''},
    name: 'ProcureWizard',
    component: WholesaleProcureWizard,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },
  {
    path: '/wholesale/homesliders',
    params: { type: ''},
    name: 'WholesaleHomeSliders',
    component: WholesaleHomeSliders,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },
  {
    path: '/wholesale/homesliders/new',
    params: { type: ''},
    name: 'WholesaleHomeSlidersNew',
    component: WholesaleHomeSlidersNew,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },
  {
    path: '/wholesale/homesliders/:id',
    params: { type: ''},
    name: 'WholesaleHomeSlidersView',
    component: WholesaleHomeSlidersView,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },
  {
    path: '/wholesale/menubanners',
    params: { type: ''},
    name: 'WholesaleMenuBanners',
    component: WholesaleMenuBanners,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },
  {
    path: '/wholesale/popular',
    params: { type: ''},
    name: 'WholesalePopular',
    component: WholesalePopular,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },
  {
    path: '/warehouse/stocktake',
    name: 'WarehouseStocktake',
    component: WarehouseStocktakes,
    meta: { authOnly: true, navName: 'Warehouse' }
  },
  {
    path: '/warehouse/stocktake/new',
    name: 'StockTakeNew',
    component: WarehouseStocktakeNew,
    meta: { authOnly: true, navName: 'Warehouse' }
  },
  {
    path: '/warehouse/stocktake/:id',
    name: 'StockTakeView',
    component: WarehouseStocktakeView,
    meta: { authOnly: true, navName: 'Warehouse' }
  },
  {
    path: '/warehouse/stocktake/category/:id',
    name: 'StockTakeCategoryView',
    component: WarehouseStocktakeViewCategory,
    meta: { authOnly: true, navName: 'Warehouse' }
  },
  
  
  /*
  {
    path: '/wholesale/products',
    params: { type: ''},
    name: 'Products',
    component: Products,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },
  {
    path: '/wholesale/products/new',
    params: { type: ''},
    name: 'ProductsNew',
    component: ProductsNew,
    meta: { requiresAuth: true, navName: 'Wholesale' }
  },*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
      if(!store.getters.isAuthenticated) {
          next({
              path: '/',
              redirect: to.fullPath
          });
      }

      next();
  } else {
      next();
  }
});


export default router
