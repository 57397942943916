<template>
  <div id="modalOverlay">
      <div id="modalWindow">
        <div class="row">
          <div class="col-sm-12" style="margin-bottom: 15px;">
            <h3>Edit Contact</h3>
          </div>
        </div>
        <form @submit.prevent="updateContact">
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-4">
                <label>First Name</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="contacts.first_name" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Last Name</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="contacts.last_name" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Email</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="contacts.email" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Phone</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="contacts.phone_number" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Mobile</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text"  v-model="contacts.mobile" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Wholesale Platform</label>
              </div>
              <div class="col-sm-8">
                <label class="checkboxContainer" style="margin-top: 15px;">
                  <input type="checkbox" v-model="contacts.wholesale_platform_active">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Password</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text" v-model="contacts.wholesale_platform_password" />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-4">
                <label>Title</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text"  v-model="contacts.job_title" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Department</label>
              </div>
              <div class="col-sm-8">
                <input type="text" class="text"  v-model="contacts.department" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <label>Notes</label>
              </div>
              <div class="col-sm-8">
                <textarea v-model="contacts.notes" rows="6"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4"></div>
              <div class="col-sm-8">
                <button type="submit" name="submit">Save Contact</button>
              </div>
            </div>
          </div>
        </div>
        </form>
        <router-link :to="{name: 'CustomersEdit', params: { id: $route.params.id }}"><font-awesome-icon icon="plus" class="modalClose" /></router-link>
      </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      contacts: {}
    }
  },
  created () {
    axios
      .get(`/contacts/${this.$route.params.cid}`)
      .then((res) => {
        this.contacts = res.data
      })
  },
  methods: {
    updateContact () {
      axios
        .put(`/contacts/${this.$route.params.cid}`, this.contacts)
        .then(response => (
          this.$toast.success("Customer saved", {}),
          this.$router.push({ name: 'CustomersEdit', params: { id: this.$route.params.id } })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }
  }
}
</script>
