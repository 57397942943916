<template>
  <div id="app">
    <!--<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <router-view/>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=IM+Fell+English&family=Open+Sans:wght@400;600;700&display=swap');
</style>

<style lang="scss">
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
}

</style>

<script>
export default {
   methods: {
      logout() {
         this.$store.dispatch('logout');
      }
   },
   computed: {
      isAuthenticated() {
         return this.$store.getters.isAuthenticated;
      }
   }
}
</script>
