<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Product Details</h2>
                  </div>
                </div>
                <form @submit.prevent="addProduct">
                <div class="row">
                  <div class="col-sm-4">
                    <label>Product Name</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="product.name" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>SKU</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="product.sku" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Product Type</label>
                  </div>
                  <div class="col-sm-8">
                    <div class="commerceSelect">
                      <select v-model="product.type">
                        <option value="Andalucian Prawns & Seafood">Andalucian Prawns & Seafood</option>
                        <option value="Bakery">Bakery</option>
                        <option value="Beer & Cider">Beer & Cider</option>
                        <option value="Boneless Hams">Boneless Hams</option>
                        <option value="Bonito Tuna">Bonito Tuna</option>
                        <option value="Bottarga">Bottarga</option>
                        <option value="British Cheeses">British Cheeses</option>
                        <option value="Canned and Jarred Seafood">Canned and Jarred Seafood</option>
                        <option value="Cantábrico Anchovies & Boquerones">Cantábrico Anchovies & Boquerones</option>
                        <option value="Caviar">Caviar</option>
                        <option value="Cazuelas and Paella Pans">Cazuelas and Paella Pans</option>
                        <option value="Cheese Accompaniaments & Snacks">Cheese Accompaniaments & Snacks</option>
                        <option value="Cheese Pieces">Cheese Pieces</option>
                        <option value="Cheeses">Cheeses</option>
                        <option value="Chorizo Sausage">Chorizo Sausage</option>
                        <option value="Cooked Meat">Cooked Meat</option>
                        <option value="El Capricho">El Capricho</option>
                        <option value="Flavoured Oils">Flavoured Oils</option>
                        <option value="Foie Gras & Paté">Foie Gras & Paté</option>
                        <option value="Fresh Iberico Pork">Fresh Iberico Pork</option>
                        <option value="Frozen Croquetas">Frozen Croquetas</option>
                        <option value="Galician Beef">Galician Beef</option>
                        <option value="Galician Beef Steaks">Galician Beef Steaks</option>
                        <option value="Gin">Gin</option>
                        <option value="Ham Stands and Knives">Ham Stands and Knives</option>
                        <option value="Hampers & Gifts">Hampers & Gifts</option>
                        <option value="Iberico Charcuteria">Iberico Charcuteria</option>
                        <option value="Italian Charcuteria">Italian Charcuteria</option>
                        <option value="Liqueurs & Spirits">Liqueurs & Spirits</option>
                        <option value="Milk-fed Lamb and Suckling Pig">Milk-fed Lamb and Suckling Pig</option>
                        <option value="Morcilla de Burgos">Morcilla de Burgos</option>
                        <option value="Octopus">Octopus</option>
                        <option value="Olive Oils">Olive Oils</option>
                        <option value="Olives & Pickles">Olives & Pickles</option>
                        <option value="Other Charcuteria">Other Charcuteria</option>
                        <option value="Pomace Oil">Pomace Oil</option>
                        <option value="Red Wines">Red Wines</option>
                        <option value="Rice & Pulses">Rice & Pulses</option>
                        <option value="Rose Wines">Rose Wines</option>
                        <option value="Salt Cod">Salt Cod</option>
                        <option value="Sea Salt">Sea Salt</option>
                        <option value="Serrano Ham">Serrano Ham</option>
                        <option value="Sherry Wines">Sherry Wines</option>
                        <option value="Sliced Charcuteria">Sliced Charcuteria</option>
                        <option value="Sparkling Wines">Sparkling Wines</option>
                        <option value="Spices">Spices</option>
                        <option value="Squid Ink & Paella Stock">Squid Ink & Paella Stock</option>
                        <option value="Sweet Treats">Sweet Treats</option>
                        <option value="Truffle Products">Truffle Products</option>
                        <option value="Vegetables & Others">Vegetables & Others</option>
                        <option value="Vinegars">Vinegars</option>
                        <option value="Wagyu Beef">Wagyu Beef</option>
                        <option value="White Wines">White Wines</option>
                        <option value="Whole Hams">Whole Hams</option>
                        <option value="Wood & Charcoal">Wood & Charcoal</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Weight Type</label>
                  </div>
                  <div class="col-sm-8">
                    <div class="commerceSelect">
                      <select v-model="product.weight_type">
                        <option value="unit">Unit</option>
                        <option value="kg">Kg</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Case Size</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="product.case_size" />
                  </div>
                </div>
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    
                  </div>
                  <div class="col-sm-8">
                    <button type="submit" name="submit">Create Product</button>
                  </div>
                </div>
                </form>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      product: {}
    }
  },
  methods: {
    addProduct () {
      axios
        .post('/products', this.product)
        .then(response => (
          this.$toast.success("Product created", {}),
          this.$router.push({ name: 'Products' })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }
  }
}
</script>
