<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <header class="titleRow">
      <div class="row">
        <div class="col-sm-4">
            <h4>Customers</h4>
        </div>
        <div class="col-sm-5">
          <input type="text" v-model="keywordSearch" v-on:keyup.enter="searchCompanies" placeholder="Search by name, company code or tags ..." />
        </div>
        <div class="col-sm-2">
          <div align="right">
            <router-link to="/customers/new" class="btnGreenHeader" style="margin-top: -3px;">Create New</router-link>
          </div>
        </div>
        <div class="col-sm-1">
            
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <ul class="filterNav filterNavBottom">
            <li class="active">All</li>
          </ul>
        </div>
      </div>
    </header>
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="normalCol">Name</div>
          <div class="normalCol">Company Type</div>
          <div class="normalCol">Email Address</div>
          <div class="normalCol">Phone Number</div>
          <div class="normalCol">Status</div>
          <div class="normalCol">Created</div>
          <div class="normalCol">Last Updated</div>
        </div>
      </div>
    </div>
    <div class="row" v-for="company in companies" :key="company.id">
      <router-link class="resultLink" :to="{name: 'CustomersEdit', params: { id: company.id }}">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol">
            <div align="center">
              <input type="checkbox" name="checkbox" />
            </div>
          </div>
          <div class="normalCol">{{ company.name }}</div>
          <div class="normalCol">{{ company.company_type }}</div>
          <div class="normalCol">{{ company.email }}</div>
          <div class="normalCol">{{ company.phone }}</div>
          <div class="normalCol"><span class="itemStatus" :class="company.status">{{ company.status }}</span></div>
          <div class="normalCol">{{ company.created_at | formatDate }}</div>
          <div class="normalCol">{{ company.updated_at | formatDate }}</div>
        </div>
      </div>
      </router-link>
    </div>
  </div>
  <div class="paginationSpacer"></div>
  <div class="paginationContainer">
    <div class="row">
      <div class="col-sm-12">
        <div align="right">
          Showing page {{ pagination.current_page }} of {{ pagination.last_page }} <a class="btnPaginate">Previous</a> <a class="btnPaginate">Next</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      keywordSearch: null,
      companies: [],
      pagination: []
    }
  },
  created () {
    axios
      .get('/companies')
      .then(response => {
        this.companies = response.data.data
        this.pagination = response.data
      })
  },
  /* watch: {
    keywordSearch (after, before) {
      this.searchCompanies()
    }
  }, */
  methods: {
    searchCompanies () {
      const keywords = { keywords: this.keywordSearch }
      axios
        .post('/companies/search', keywords)
        .then(response => {
          this.companies = response.data.data
          this.pagination = response.data
        })
    }
  }
}
</script>
