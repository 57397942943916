<template>
  <div class="app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px; padding-right: 0px;">
                <left-nav></left-nav>
                 <div id="appContainer" class="bgPage">
                   <header class="titleRow">
                    <div class="row">
                      <div class="col-sm-11">
                          <h4>Orders / New</h4>
                      </div>
                      <div class="col-sm-1">
                          <div align="right">ICON</div>
                      </div>
                    </div>
                  </header>
                  <app-content></app-content>
                 </div>
             </div>
        </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import LeftNav from '@/components/nav/Orders.vue'
import AppContent from '@/views/orders/OrdersNew.vue'

export default {
  // name: 'Home',
  components: {
    LeftNav,
    AppContent
  }
}
</script>
