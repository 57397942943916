<template>
  <div id="leftNavMain">
    <start-nav></start-nav>
    <first-nav></first-nav>
    <second-nav></second-nav>
  </div>
</template>

<script>
// @ is an alias to /src
import StartNav from '@/components/nav/StartNav.vue'
import FirstNav from '@/components/nav/FirstLevelNav.vue'
import SecondNav from '@/components/nav/StockControlSecond.vue'

export default {
  // name: 'Home',
  components: {
    FirstNav,
    StartNav,
    SecondNav
  }
}
</script>
