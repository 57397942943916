<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-4">
          <div class="itemsGroup">
            <div class="itemsList">
              <dt class="item">Type</dt>
              <dd class="item">{{ company.company_type }}</dd>
            </div>
            <div class="itemsList">
              <dt class="item">Code</dt>
              <dd class="item">{{ company.company_code }}</dd>
            </div>
            <div class="itemsList">
              <dt class="item">Tax Number</dt>
              <dd class="item">{{ company.tax_number }}</dd>
            </div>
            <div class="itemsList">
              <dt class="item">EORI Number</dt>
              <dd class="item">{{ company.eori_number }}</dd>
            </div>
            <div class="itemsList">
              <dt class="item">Tags</dt>
              <dd class="item">{{ company.tags }}</dd>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="itemsGroup">
            <div class="itemsList">
              <dt class="item">Phone</dt>
              <dd class="item">{{ company.phone }}</dd>
            </div>
            <div class="itemsList">
              <dt class="item">Email</dt>
              <dd class="item">{{ company.email }}</dd>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="itemsGroup">
            <div class="itemsList">
              <dt class="item">Assigned To</dt>
              <dd class="item"></dd>
            </div>
            <div class="itemsList">
              <dt class="item">Default Price List</dt>
              <dd class="item">{{ company.default_price_list_id }}</dd>
            </div>
            <div class="itemsList">
              <dt class="item">Default Discount Rate</dt>
              <dd class="item">{{ company.default_discount_rate }}</dd>
            </div>
            <div class="itemsList">
              <dt class="item">Default Payment Term</dt>
              <dd class="item">{{ company.default_payment_term }}</dd>
            </div>
            <div class="itemsList">
              <dt class="item">Card Stored</dt>
              <dd class="item"></dd>
            </div>
          </div>
        </div>
      </div>
    </div>
    <header>
      <div class="row">
        <div class="col-sm-12">
          <ul class="filterNav">
            <router-link :to="{name: 'CustomersEdit', params: { id: $route.params.id }}"><li>Contacts</li></router-link>
            <router-link :to="{name: 'CustomersAddress', params: { id: $route.params.id }}"><li>Addresses</li></router-link>
            <router-link :to="{name: 'CustomersNotes', params: { id: $route.params.id }}"><li>Notes</li></router-link>
            <router-link :to="{name: 'CustomersOrders', params: { id: $route.params.id }}"><li>Orders</li></router-link>
            <!--<router-link :to="{name: 'CustomersPriceLists', params: { id: $route.params.id }}"><li>Price Lists</li></router-link>
            <li>Insights</li>-->
          </ul>
        </div>
      </div>
    </header>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      company: {}
    }
  },
  created () {
    axios
      .get(`/companies/${this.$route.params.id}`)
      .then((res) => {
        this.company = res.data
      })
  },
  methods: {
  }
}
</script>
