<template>
    <nav class="firstNav">
        <ul @mouseenter="hideSecondMenu" @mouseleave="showSecondMenu">
            <!--<router-link to="/dashboard" style="color: #FFFFFF; text-decoration: none;"><li class="active"><span><font-awesome-icon icon="table" /></span> Dashboard</li></router-link>-->
            <router-link to="/orders" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="file-alt" /></span> Orders</li></router-link>
            <router-link to="/customers" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="user-friends" /></span> Customers</li></router-link>
            <router-link to="/products" style="color: #FFFFFF; text-decoration: none;"><li><span><font-awesome-icon icon="wine-bottle" /></span> Products</li></router-link>
            <!--<router-link to="/batch" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="tags" /></span> Batch</li></router-link>-->
            <!--<li><span><font-awesome-icon icon="cogs" /></span> Settings</li>-->
            <router-link to="/purchase-orders" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="dolly" /></span> Stock Control</li></router-link>
            
            <router-link to="/wholesale/documents" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="table" /></span> Wholesale Platform</li></router-link>

            <router-link to="/warehouse/stocktake" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="bars-progress" /></span> Warehouse</li></router-link>

            <!--<router-link to="/wholesale/documents" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="clipboard-list" /></span> Documents</li></router-link>
            <router-link to="/wholesale/topbar" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="bars-progress" /></span> Top Bar</li></router-link>
            <router-link to="/wholesale/homesliders" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="table" /></span> Home Sliders</li></router-link>
            <router-link to="/wholesale/menubanners" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="image" /></span> Menu Banners</li></router-link>-->
            <router-link to="/wholesale/procurewizard" style="color: #FFFFFF;"><li><span><font-awesome-icon icon="cash-register" /></span> Procure Wizard</li></router-link>
            <a href="/"><li><span><font-awesome-icon icon="right-from-bracket" /></span> Log Out</li></a>
        </ul>

        

    </nav>
</template>

<script>
export default {
  methods: {
    hideSecondMenu: function (e) {
      document.getElementById('leftNavSecond').classList.remove('active')
    },
    showSecondMenu: function (e) {
      document.getElementById('leftNavSecond').classList.add('active')
    }
  }
}
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
/*
export default {
  name: 'Home',
  components: {
    HelloWorld
  }
} */
</script>
