<template>
<div>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="row">
      <div class="col-sm-12">
        <div id="resultsHeader">
          <div class="checkboxCol"></div>
          <div class="normalCol">Order No</div>
          <div class="normalCol">Status</div>
          <div class="smallCol">Received</div>
          <div class="normalCol">Supplier</div>
          <div class="normalCol">Cost</div>
          <div class="normalCol">Due</div>
          <div class="normalCol">Last Updated</div>
        </div>
      </div>
    </div>
    <div class="row" v-for="purchaseorder in purchaseorders" :key="purchaseorder.id">
      <router-link :to="{name: 'PurchaseOrdersView', params: { id: purchaseorder.id }}">
      <div class="col-sm-12">
        <div id="resultsBody">
          <div class="checkboxCol">
            <div align="center">
              <input type="checkbox" name="checkbox" />
            </div>
          </div>
          <div class="normalCol">{{ purchaseorder.po_number }}</div>
          <div class="normalCol"><span class="itemStatus" :class="purchaseorder.status">{{ purchaseorder.status }}</span></div>
          <div class="smallCol"><span class="emptyPoint" :class="purchaseorder.received"></span></div>
          <div class="normalCol">{{ purchaseorder.company_id }}</div>
          <div class="normalCol" v-if="purchaseorder.currency === 'GBP'">&pound;{{ purchaseorder.total }}</div>
          <div class="normalCol" v-if="purchaseorder.currency === 'EUR'">&euro;{{ purchaseorder.total }}</div>
          <div class="normalCol">{{ purchaseorder.due_at | formatDate }}</div>
          <div class="normalCol">{{ purchaseorder.updated_at | formatDate }}</div>
        </div>
      </div>
      </router-link>
    </div>
  </div>
  <div class="paginationContainer">
    <div class="row">
      <div class="col-sm-12">
        <div align="right">
          Showing page {{ pagination.current_page }} of {{ pagination.last_page }} <a class="btnPaginate">Previous</a> <a class="btnPaginate">Next</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      purchaseorders: {},
      pagination: {}
    }
  },
  created () {
    axios
      .get('/purchaseorders?page=')
      .then((res) => {
        this.purchaseorders = res.data.data
        this.pagination = res.data
      })
  }
}
</script>
