<template>
    <div>
        <input type="text" class="orderProductInput" v-model="productInput" @input="handleInput" @focus="onFocus" @blur="onBlur" />
        <div class="inputResults" v-show="isVisible">
            <ul>
            <li v-for="variant in variants" :key="variant.id" @click.prevent="setProductVariant(variant.id, variant.name, variant.sku)">
                <div class="row">
                <div class="col-sm-8">
                    {{variant.name}}<br /><span>{{variant.sku}}</span>
                </div>
                <div class="col-sm-4">
                    Available:
                </div>
                </div>
            </li>
            </ul>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
  prop: ['value'],
  data () {
    return {
      // content: this.value,
      variants: [],
      productInput: null,
      isVisible: false
    }
  },
  watch: {
    productInput (after, before) {
      if (before === null) {
        this.DoNothing()
      } else {
        this.searchProduct()
      }
    }
  },
  created () {
    axios
      .get('/variants/list')
      .then(response => {
        this.variants = response.data
      })
  },
  methods: {
    onFocus: function () {
      this.isVisible = true
    },
    onBlur: function () {
      // this.isVisible = false
    },
    searchProduct () {
      const keywords = { keywords: this.productInput }
      axios
        .post('/variants/search', keywords)
        .then(response => {
          this.variants = response.data
        })
    },
    handleInput (id, name, sku) {
      if (name === null || name === undefined) {
        this.DoNothing()
      } else {
        this.$emit('input', this.productInput)
        this.productInput = name + ' (' + sku + ')'
      }
    },
    setProductVariant (id, name, sku) {
      this.productInput = id
      this.isVisible = false
      this.handleInput(id, name, sku)
    }
  }
}
</script>
