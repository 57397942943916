<template>
  <div class="app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px; padding-right: 0px;">
                <left-nav></left-nav>
                 <div id="appContainer" class="bgPage">
                   <header class="titleRow">
                    <div class="row">
                      <div class="col-sm-11">
                        <h4><router-link :to="{name: 'PurchaseOrders'}">Purchase Orders</router-link> / <router-link :to="{name: 'PurchaseOrdersView', params: { id: $route.params.id }}">{{ purchaseorder.po_number }}</router-link></h4>
                      </div>
                      <div class="col-sm-1">
                          
                      </div>
                    </div>
                  </header>
                  <app-content></app-content>
                 </div>
             </div>
        </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'

// @ is an alias to /src
import LeftNav from '@/components/nav/StockControl.vue'
import AppContent from '@/views/purchase-orders/View.vue'

export default {
  // name: 'Home',
  components: {
    LeftNav,
    AppContent
  },
  data () {
    return {
      purchaseorder: {}
    }
  },
  created () {
    axios
      .get(`/purchaseorders/${this.$route.params.id}`)
      .then((res) => {
        this.purchaseorder = res.data
      })
  }
}
</script>
