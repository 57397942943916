<template>
  <div class="container-fluid" style="padding-left: 0px; padding-right: 0px;">
    <div class="pageContainer">
      <div class="row">
        <div class="col-sm-9">
          <div class="pageSubContainer">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-sm-12">
                    <h2>Stocktake</h2>
                  </div>
                </div>
                <form @submit.prevent="addStocktake">
                <div class="row">
                  <div class="col-sm-4">
                    <label>Reference</label>
                  </div>
                  <div class="col-sm-8">
                    <input type="text" class="text" v-model="stocktake.reference" />
                  </div>
                </div>
                
                <div class="row" style="margin-top:30px;">
                  <div class="col-sm-12">
                    <hr />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <button type="submit" name="submit">Create Stocktake</button>
                  </div>
                </div>
                </form>
              </div>
              <div class="col-sm-1"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-3"></div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      stocktake: {}
    }
  },
  methods: {
    addStocktake () {
      axios
        .post('/stocktakes', this.stocktake)
        .then(response => (
          this.$toast.success("Stocktake created", {}),
          this.$router.push({ name: 'WarehouseStocktake' })
        ))
        .catch(err => console.log(err))
        // .finally(() => this.loading = false)
    }
  }
}
</script>
