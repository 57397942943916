<template>
  <div class="app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12" style="padding-left: 0px; padding-right: 0px;">
                <left-nav></left-nav>
                 <div id="appContainer">
                   <header class="titleRow">
                    <div class="row">
                      <div class="col-sm-4">
                          <h4><router-link :to="{name: 'Products'}">Products</router-link> / New</h4>
                      </div>
                      <div class="col-sm-5">
                      </div>
                      <div class="col-sm-2">
                      </div>
                      <div class="col-sm-1">
                          <div align="right">ICON</div>
                      </div>
                    </div>
                  </header>
                  <new-product></new-product>
                 </div>
             </div>
        </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import LeftNav from '@/components/nav/Products.vue'
import NewProduct from '@/views/products/New.vue'

export default {
  // name: 'Home',
  components: {
    LeftNav,
    NewProduct
  }
}
</script>
